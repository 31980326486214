import React from 'react';
import PropTypes from 'prop-types';
import './Message.css';
import config from '../../../../config';
import { sendAvaClickedEvent } from '../../../../api/ParentAPI';
import clientPic from '../../../../images/client.png';
import managerPic from '../../../../images/manager.png';
import botPic from '../../../../images/bot.png';

export const MessageType = PropTypes.shape({
  sender: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isModerated: PropTypes.number.isRequired,
});


const Message = ({
  message, isMyMsg, timeForDivider, creator,
}) => {
  const {
    id,
    sender,
    text,
    time,
    isModerated,
  } = message;

  let pic = sender === 'bot' ? botPic : managerPic;
  pic = sender === 'client' ? clientPic : pic;

  if (sender === config.systemUsername) {
    return (
      <li className="system_message_item" data-key={id}>
        <span className="system_message">{text}</span>
      </li>
    );
  }

  const timeDate = new Date(time);
  const currentClassName = isMyMsg ? 'message message_my' : 'message';
  // const avatarClassName = isMyMsg ? 'message__userpic-hiding' : 'message__userpic';
  const minutes = timeDate.getMinutes() < 10 ? `0${timeDate.getMinutes()}` : timeDate.getMinutes();
  const timeStr = `${timeDate.getHours()}:${minutes}`;
  // const msgInfo = isMyMsg ? `${timeStr}` : `${sender}, ${timeStr}`;

  let messageJsx;
  if (isModerated === 2 || (isModerated !== 2 && sender === creator)) {
    messageJsx = (
      <li className={currentClassName}>
        <div className="msg_user_box">
          <div
            className="message__userpic"
            onClick={() => sendAvaClickedEvent(sender)}
          >
            <img src={pic} alt={sender} />
          </div>
          <div className="message__time">{timeStr}</div>
        </div>
        <div className="message__box-group">
          <div className="message__balloon">{text}</div>
        </div>
      </li>
    );
  }

  return (
    <>
      {(timeForDivider !== '')
      && (
        <li>
          <span className="message__new-day">
            {timeForDivider}
          </span>
        </li>
      )}
      {messageJsx}
    </>
  );
};

Message.propTypes = {
  message: MessageType.isRequired,
  isMyMsg: PropTypes.bool.isRequired,
  timeForDivider: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired,
};

export default Message;
