import actions from '../actions';

const messagesInitialState = {
  // roomUUID1: {
  //   chatScrollPosition: {
  //     scrollTop: 0,
  //     top: 0,
  //   },
  //   messages: [
  //     {
  //       sender: 'monterey', text: 'some text room1 4', time: '2002-07-01T16:50:05Z', id: '1',
  //     },
  //   ],
  //   unreadEndReached: false,
  //   historyEndReached: false,
  //   initialHistoryFetched: false,
  // },
};

export const emptyRoom = {
  messages: [],
  historyEndReached: false,
  unreadEndReached: false,
  chatScrollPosition: {
    scrollTop: 0,
    top: 0,
  },
  initialHistoryFetched: false,
};

export default (state = messagesInitialState, action) => {
  switch (action.type) {
  case actions.GET_MESSAGES_STORE: {
    const rooms = state;
    const { roomId, messages: newMessages } = action.payload;
    const room = rooms[roomId] || { ...emptyRoom };
    const { messages: oldMessages = [], historyEndReached } = room;
    const oldMessageIds = oldMessages.map((oldMessage) => oldMessage.id);
    const filteredMessages = newMessages
      .filter((newMessage) => !oldMessageIds.includes(newMessage.id));
    return {
      ...rooms,
      [roomId]: {
        ...room,
        messages: [...oldMessages, ...filteredMessages],
        historyEndReached,
      },
    };
  }
  case actions.GET_ROOM_UNREAD_MESSAGES_STORE: {
    const rooms = state;
    const { roomId, messages: newMessages } = action.payload;
    const room = rooms[roomId] || { ...emptyRoom };
    const { messages: oldMessages = [], historyEndReached } = room;
    const oldMessageIds = oldMessages.map((oldMessage) => oldMessage.id);
    const filteredMessages = newMessages
      .filter((newMessage) => !oldMessageIds.includes(newMessage.id));
    return {
      ...rooms,
      [roomId]: {
        ...room,
        messages: [...filteredMessages, ...oldMessages],
        historyEndReached,
      },
    };
  }
  case actions.UPDATE_ROOM_SCROLL: {
    const { chatScrollPosition, roomId } = action.payload;
    const room = state[roomId] || { ...emptyRoom };
    return {
      ...state,
      [roomId]: {
        ...room,
        chatScrollPosition,
      },
    };
  }

  case actions.SEND_MESSAGE_STORE:
  {
    const rooms = state;
    const {
      id,
      sender,
      roomId,
      text,
      time,
      isModerated,
    } = action.payload;
    const room = rooms[roomId] || { ...emptyRoom };
    const { messages: oldMessages = [] } = room;
    room.messages = [{
      sender, text, time, id, isModerated,
    }, ...oldMessages];
    return {
      ...rooms,
      [roomId]: {
        ...room,
        chatScrollPosition: {
          ...room.chatScrollPosition,
          top: 1,
        },
      },
    };
  }
  case actions.MESSAGE_RECEIVED_STORE: {
    const rooms = state;
    const {
      id,
      sender,
      roomId,
      text,
      time,
      isModerated,
    } = action.payload;
    const room = rooms[roomId] || { ...emptyRoom };
    const { messages: oldMessages = [] } = room;
    return {
      ...rooms,
      [roomId]: {
        ...room,
        messages: [{
          sender, text, time, id, isModerated,
        }, ...oldMessages],
      },
    };
  }
  case actions.HISTORY_END_REACHED_STORE: {
    const rooms = state;
    const { roomId } = action.payload;
    const room = rooms[roomId] || { ...emptyRoom };
    let { historyEndReached } = room;
    historyEndReached = true;
    return {
      ...rooms,
      [roomId]: { ...room, historyEndReached },
    };
  }
  case actions.UNREAD_END_REACHED_STORE: {
    const rooms = state;
    const { roomId } = action.payload;
    const room = rooms[roomId] || { ...emptyRoom };
    let { unreadEndReached } = room;
    unreadEndReached = true;
    return {
      ...rooms,
      [roomId]: { ...room, unreadEndReached },
    };
  }
  case actions.SET_INITIAL_HISTORY_FETCHED: {
    const rooms = state;
    const { roomId, initialHistoryFetched } = action.payload;
    const room = rooms[roomId] || { ...emptyRoom };
    return {
      ...rooms,
      [roomId]: { ...room, initialHistoryFetched },
    };
  }
  case actions.CLEAR_HISTORY_STORE: {
    const rooms = state;
    const { roomId } = action.payload;
    return {
      ...rooms,
      [roomId]: {
        ...emptyRoom,
        historyEndReached: true,
        initialHistoryFetched: true,
      },
    };
  }
  case actions.DELETE_ROOM_STORE: {
    const rooms = state;
    const { roomId } = action.payload;
    delete rooms[roomId];

    return {
      ...rooms,
    };
  }
  case actions.SET_MESSAGES_STORE:
    return {
      ...action.payload,
    };
  default:
    return state;
  }
};
