// import {
// MdGroupAdd,
// MdClose,
// } from 'react-icons/md';
import React from 'react';
// import { useDispatch } from 'react-redux';
// import { ToggleLayer } from 'react-laag';
import './ToolBar.css';
import PropTypes from 'prop-types';
// import { sendCloseChat } from '../../../api/ParentAPI';
// import actions from '../../../actions';
// import MenuList from '../../Common/MenuList';

const ToolBar = (props) => {
  const {
    // toggleCreateRoomCb,
    // toggleStartPrivate,
    setSearchString,
    searchString,
  } = props;
  // const dispatch = useDispatch();
  // const handleCloseBtn = () => {
  //   dispatch({
  //     type: actions.SET_ACTIVE_ROOM,
  //     payload: '',
  //   });
  //   sendCloseChat();
  // };

  return (
    <>
      <div className="sidebar__header">
        <div className="sidebar__search">
          <input
            className="sidebar__search-input"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            placeholder="Search by name"
          />
        </div>
        <div className="toolbar">
          {/* <ToggleLayer */}
          {/*  renderLayer={({ */}
          {/*    isOpen, */}
          {/*    layerProps, */}
          {/*    close, */}
          {/*  }) => isOpen && ( */}
          {/*    <div */}
          {/*      className="toolbar__room-menu" */}
          {/*      ref={layerProps.ref} */}
          {/*      style={{ */}
          {/*        ...layerProps.style, */}
          {/*      }} */}
          {/*    > */}
          {/*      <MenuList list={[ */}
          {/*        { cb: () => { toggleCreateRoomCb(); close(); }, desc: 'Create a group chat' }, */}
          {/*        { cb: () => { toggleStartPrivate(); close(); }, desc: 'Private conversation' }, */}
          {/*      ]} */}
          {/*      /> */}

          {/*    </div> */}
          {/*  )} */}
          {/*  placement={{ */}
          {/*    anchor: 'RIGHT_CENTER', */}
          {/*    possibleAnchors: [ */}
          {/*      'TOP_CENTER', */}
          {/*      'TOP_LEFT', */}
          {/*      'TOP_RIGHT', */}
          {/*      'LEFT_TOP', */}
          {/*      'LEFT_CENTER', */}
          {/*      'LEFT_BOTTOM', */}
          {/*      'BOTTOM_LEFT', */}
          {/*    ], */}
          {/*    autoAdjust: true, */}
          {/*    triggerOffset: 0, */}
          {/*  }} */}
          {/*  closeOnOutsideClick */}
          {/* > */}
          {/*  {({ triggerRef, toggle }) => ( */}
          {/*    <div ref={triggerRef} onClick={toggle} className="toolbar__add-room-btn"> */}
          {/*      <MdGroupAdd title="Create room" /> */}
          {/*    </div> */}
          {/*  )} */}
          {/* </ToggleLayer> */}
          {/* <div className="toolbar__close-btn" onClick={handleCloseBtn}> */}
          {/*  <MdClose title="Create room" /> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

ToolBar.propTypes = {
  // toggleCreateRoomCb: PropTypes.func.isRequired,
  // toggleStartPrivate: PropTypes.func.isRequired,
  setSearchString: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
};

export default ToolBar;
