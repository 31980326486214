import actions from '../actions';

const userInfoInitialState = {
  username: '',
  isGuest: false,
};

export default (state = userInfoInitialState, action) => {
  switch (action.type) {
  case actions.SET_USERINFO: {
    const { username, isGuest } = action.payload;
    return { ...state, username, isGuest };
  }
  default:
    return state;
  }
};
