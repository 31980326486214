import { put, select } from 'redux-saga/effects';
import actions from '../actions';
import { getMessages } from '../selectors';


export default function* (a) {
  const { msgId, roomId } = a.payload;
  try {
    if (typeof roomId !== 'string' || roomId.length === 0) {
      console.error('modMessageRejected saga: invalid roomId parameter');
      return;
    }
    if (typeof msgId !== 'number' || msgId < 1) {
      console.error('modMessageRejected saga: invalid msgId parameter');
      return;
    }

    const messagesState = yield select(getMessages);
    const { messages } = messagesState[roomId] || {};
    if (!messages) return;

    const msgIndex = messages.findIndex((msg) => msg.id === msgId);
    if (msgIndex === -1) {
      return;
    }

    messages[msgIndex].isModerated = 0;

    messagesState[roomId] = {
      ...messagesState[roomId], messages,
    };

    yield put({
      type: actions.SET_MESSAGES_STORE,
      payload: messagesState,
    });
  } catch (e) {
    console.error(e);
  }
}
