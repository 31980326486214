import { put, select } from 'redux-saga/effects';
import actions from '../actions';
import {
  getRooms,
  getUsername,
  getMessages,
} from '../selectors';


export default function* messageReceived(action) {
  try {
    const {
      sender, text, roomId, id, time, isModerated, type,
    } = action.payload;

    if (typeof sender !== 'string' || sender.length === 0) {
      console.error(new Error('invalid "sender" parameter'));
      return;
    }
    if (typeof text !== 'string' || text.length === 0) {
      console.error(new Error('invalid "text" parameter'));
      return;
    }
    if (typeof roomId !== 'string' || roomId.length === 0) {
      console.error(new Error('invalid "roomId" parameter'));
      return;
    }
    if (typeof time !== 'string' || time.length === 0) {
      console.error(new Error('invalid "time" parameter'));
      return;
    }
    if (typeof isModerated !== 'number' || isModerated < 0) {
      console.error(new Error('invalid "isModerated" parameter'));
      return;
    }

    const messagesStore = yield select(getMessages);
    const rooms = yield select(getRooms);
    const username = yield select(getUsername);
    let isAppend = false;
    const roomMsgs = messagesStore[roomId];
    const room = rooms[roomId] || {};
    const { messages = [] } = roomMsgs || {};
    const { id: lastMessageId = 0 } = room?.latestMsg || {};
    let { oldestMsgId = 0, unreadMsgCount = 0 } = room || {};
    if (messages.length === 0 && lastMessageId === 0) {
      isAppend = true;
    }
    if (messages.length > 0 && messages[0].id === lastMessageId) {
      isAppend = true;
    }
    const storeMsg = {
      sender, text, time, id, roomId, isModerated,
    };

    oldestMsgId = oldestMsgId === 0 ? id : oldestMsgId;
    oldestMsgId = sender !== username ? oldestMsgId : 0;
    unreadMsgCount = sender !== username ? ++unreadMsgCount : 0;

    if (!room.managerId && sender !== 'bot' && sender !== 'client' && sender !== username) {
      room.managerId = sender;
    }

    if (type === 2) {
      room.managerId = '';
      room.botActivity = true;
    }

    if (type === 1) room.botActivity = false;

    // eslint-disable-next-line no-constant-condition
    if (!id || text === 'turn off' || text === 'turn on') isAppend = false;

    yield put({
      type: actions.UPDATE_ROOM_SETTINGS_STORE,
      payload: {
        id: roomId,
        room: {
          name: roomId,
          managerId: room.managerId,
          botActivity: room.botActivity,
        },
      },
    });

    if (isAppend) {
      yield put({
        type: actions.UPDATE_ROOM_SETTINGS_STORE,
        payload: {
          id: roomId,
          room: {
            name: roomId,
            oldestMsgId,
            unreadMsgCount,
          },
        },
      });
      yield put({
        type: actions.MESSAGE_RECEIVED_STORE,
        payload: storeMsg,
      });
      yield put({
        type: actions.CHAT_UPDATED,
        payload: {
          client: sender,
          roomId,
        },
      });
      yield put({
        type: actions.ROOM_LATEST_MESSAGE,
        payload: {
          roomId,
          latestMsg: storeMsg,
        },
      });
    }
  } catch (e) {
    console.error(e);
  }
}
