const actions = {};

Object.defineProperties(actions, {
  ADD_ROOM: { value: 'ADD_ROOM', writable: false },
  DELETE_ROOM_STORE: { value: 'DELETE_ROOM_STORE', writable: false },
  ADD_USERS_TO_ROOM: { value: 'ADD_USERS_TO_ROOM', writable: false },
  DELETE_USERS_FROM_ROOM: { value: 'DELETE_USERS_FROM_ROOM', writable: false },
  DELETE_USERS_FROM_ROOM_STORE: { value: 'DELETE_USERS_FROM_ROOM_STORE', writable: false },
  GET_USER_ROOMS: { value: 'GET_USER_ROOMS', writable: false },
  GET_USER_ROOMS_STORE: { value: 'GET_USER_ROOMS_STORE', writable: false },
  CREATE_ROOM: { value: 'CREATE_ROOM', writable: false },
  CREATE_ROOM_STORE: { value: 'CREATE_ROOM_STORE', writable: false },
  UPDATE_ROOM: { value: 'UPDATE_ROOM', writable: false },
  INVITED_TO_ROOM: { value: 'INVITED_TO_ROOM', writable: false },
  INVITED_TO_ROOM_STORE: { value: 'INVITED_TO_ROOM_STORE', writable: false },
  SET_USERINFO: { value: 'SET_USERINFO', writable: false },
  GET_MESSAGES: { value: 'GET_MESSAGES', writable: false },
  GET_MESSAGES_STORE: { value: 'GET_MESSAGES_STORE', writable: false },
  SET_ACTIVE_ROOM: { value: 'SET_ACTIVE_ROOM', writable: false },
  SEND_MESSAGE: { value: 'SEND_MESSAGE', writable: false },
  SEND_MESSAGE_STORE: { value: 'SEND_MESSAGE_STORE', writable: false },
  HISTORY_END_REACHED_STORE: { value: 'HISTORY_END_REACHED_STORE', writable: false },
  MESSAGE_RECEIVED: { value: 'MESSAGE_RECEIVED', writable: false },
  MESSAGE_RECEIVED_STORE: { value: 'MESSAGE_RECEIVED_STORE', writable: false },
  USERS_ADDED: { value: 'USERS_ADDED', writable: false },
  USERS_DELETED: { value: 'USERS_DELETED', writable: false },
  USERS_DELETED_STORE: { value: 'USERS_DELETED_STORE', writable: false },
  UPDATE_ROOM_SCROLL: { value: 'UPDATE_ROOM_SCROLL', writable: false },
  SET_INITIAL_HISTORY_FETCHED: { value: 'SET_INITIAL_HISTORY_FETCHED', writable: false },
  CHAT_UPDATED: { value: 'CHAT_UPDATED', writable: false },
  UPDATE_ROOM_SETTINGS: { value: 'UPDATE_ROOM_SETTINGS', writable: false },
  UPDATE_ROOM_SETTINGS_STORE: { value: 'UPDATE_ROOM_SETTINGS_STORE', writable: false },
  ROOM_SETTINGS_UPDATED: { value: 'ROOM_SETTINGS_UPDATED', writable: false },
  CLEAR_HISTORY_STORE: { value: 'CLEAR_HISTORY_STORE', writable: false },
  ROOM_LATEST_MESSAGE: { value: 'ROOM_LATEST_MESSAGE', writable: false },
  CLEAR_HISTORY: { value: 'CLEAR_HISTORY', writable: false },
  CLOSE_CONFIRM_MODAL: { value: 'CLOSE_CONFIRM_MODAL', writable: false },
  OPEN_CONFIRM_MODAL: { value: 'OPEN_CONFIRM_MODAL', writable: false },
  GET_ROOM_UNREAD_MESSAGES: { value: 'GET_ROOM_UNREAD_MESSAGES', writable: false },
  GET_ROOM_UNREAD_MESSAGES_STORE: { value: 'GET_ROOM_UNREAD_MESSAGES_STORE', writable: false },
  NEXT_UNREAD_MESSAGE: { value: 'NEXT_UNREAD_MESSAGE', writable: false },
  NEXT_UNREAD_ID: { value: 'NEXT_UNREAD_ID', writable: false },
  UNREAD_END_REACHED_STORE: { value: 'UNREAD_END_REACHED_STORE', writable: false },
  APPROVE_MOD_MESSAGE: { value: 'APPROVE_MOD_MESSAGE', writable: false },
  APPROVED_MESSAGE: { value: 'APPROVED_MESSAGE', writable: false },
  REJECT_MOD_MESSAGE: { value: 'REJECT_MOD_MESSAGE', writable: false },
  MOD_MESSAGE_REJECTED: { value: 'MOD_MESSAGE_REJECTED', writable: false },
  SET_MESSAGES_STORE: { value: 'SET_MESSAGES_STORE', writable: false },
  START_PRIVATE: { value: 'START_PRIVATE', writable: false },
  DELETE_ROOM: { value: 'DELETE_ROOM', writable: false },
  ROOM_DELETED: { value: 'ROOM_DELETED', writable: false },
  QUIT_ROOM: { value: 'QUIT_ROOM', writable: false },
  CHANGE_BOT_ACTIVITY: { value: 'CHANGE_BOT_ACTIVITY', writable: false },
});

export default actions;
