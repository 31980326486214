import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ToolBar from './ToolBar';
import ErrorBoundary from '../Common/ErrorBoundary';
import UserRoomsList from './UserRoomsList';

const SideBar = (props) => {
  const {
    toggleCreateRoom,
    setRoomListVisibility,
    toggleStartPrivate,
  } = props;
  const [searchString, setSearchString] = useState('');

  return (
    <ErrorBoundary>
      <ToolBar
        toggleCreateRoomCb={toggleCreateRoom}
        setSearchString={setSearchString}
        searchString={searchString}
        toggleStartPrivate={toggleStartPrivate}
      />
      <UserRoomsList
        setRoomListVisibility={setRoomListVisibility}
        searchString={searchString}
        setSearchString={setSearchString}
      />
    </ErrorBoundary>
  );
};

SideBar.propTypes = {
  toggleCreateRoom: PropTypes.func.isRequired,
  setRoomListVisibility: PropTypes.func.isRequired,
  toggleStartPrivate: PropTypes.func.isRequired,
};

export default SideBar;
