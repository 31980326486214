import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IoMdSend } from 'react-icons/io';
import './SendBox.css';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import actions from '../../../actions';

const SendBox = (props) => {
  const { roomId } = props;
  const [text, setText] = useState('');
  const dispatch = useDispatch();
  const sendMsg = () => {
    dispatch({
      type: actions.SEND_MESSAGE,
      payload: {
        msgText: text,
        roomId,
      },
    });
    setText('');
  };
  const handleOnKeyDown = (event) => {
    if (event.ctrlKey && event.keyCode === 13) {
      setText(`${text}\n`);
    } else if (event.keyCode === 13) {
      event.preventDefault();
      sendMsg();
    }
    return true;
  };
  const placeholder = 'Type your message...';

  return (
    <div className="room__sendbox">
      <div className="sendbox">
        <TextareaAutosize
          value={text}
          maxRows={15}
          minRows={1}
          className="sendbox__input"
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleOnKeyDown}
          placeholder={placeholder}
        />
        <div className="sendbox__send-btn" onClick={sendMsg}><IoMdSend /></div>
      </div>
    </div>
  );
};

SendBox.propTypes = {
  roomId: PropTypes.string.isRequired,
};

export default SendBox;
