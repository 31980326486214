import { call, put, select } from 'redux-saga/effects';
import actions from '../actions';
import * as chatAPI from '../api/ChatAPI';
import {
  getMessages,
  getRooms,
} from '../selectors';

export default function* getHistoryMessages(a) {
  const { roomId, msgId, count } = a.payload;
  if (typeof roomId !== 'string' || roomId.length === 0) {
    console.log(new Error('invalid roomId param'));
    return;
  }
  if (typeof msgId !== 'number' || msgId <= 0) {
    console.log(new Error('invalid msgId param'));
    return;
  }
  if (typeof count !== 'number' || count <= 0) {
    console.log(new Error('invalid count param'));
    return;
  }
  try {
    const messagesStore = yield select(getMessages);
    const reached = messagesStore[roomId]?.historyEndReached;
    if (reached) {
      return;
    }
    const roomsStore = yield select(getRooms);
    const { moderable } = roomsStore[roomId];
    const getMessagesFunc = moderable ? chatAPI.getRoomModMessages : chatAPI.getRoomMessages;
    const { roomId: respRoomID, messages } = yield call(getMessagesFunc,
      roomId,
      msgId,
      count);

    if (respRoomID !== roomId) {
      console.log('invalid roomId field in response');
      return;
    }

    if (!Array.isArray(messages) || messages.length < count) {
      yield put({
        type: actions.HISTORY_END_REACHED_STORE,
        payload: {
          roomId,
        },
      });
    }
    if (Array.isArray(messages)) {
      yield put({
        type: actions.GET_MESSAGES_STORE,
        payload: {
          messages,
          roomId,
        },
      });
    }
  } catch (e) {
    console.log(e);
  }
}
