import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import UserRoomItem from './UserRoomItem';
import './UserRoomList.css';
import actions from '../../../actions';


const UserRoomsList = (props) => {
  const { setRoomListVisibility, searchString, setSearchString } = props;
  const rooms = useSelector((state) => state.roomsReducer.rooms);
  const activeRoomID = useSelector((state) => state.roomsReducer.activeRoomID);
  const username = useSelector((state) => state.userInfoReducer.username);
  const dispatch = useDispatch();

  const setActiveRoom = (roomId) => {
    setSearchString('');
    dispatch({
      type: actions.SET_ACTIVE_ROOM,
      payload: roomId,
    });
    setRoomListVisibility(false);
  };


  const listRooms = Object.keys(rooms).map((key) => {
    if (searchString !== ''
      && rooms[key].name.indexOf(searchString) === -1) {
      return false;
    }
    let isActiveRoom = false;
    if (key === activeRoomID) {
      isActiveRoom = true;
    }

    if (!rooms[key].latestMsg) {
      rooms[key].latestMsg = { time: '' };
    }
    if (rooms[key].managerId && rooms[key].managerId !== username) {
      return false;
    }

    return (
      <UserRoomItem
        key={key}
        room={rooms[key]}
        time={rooms[key].latestMsg.time}
        isActive={isActiveRoom}
        setActiveRoom={setActiveRoom}
        roomId={key}
      />
    );
  }).filter((room) => room !== false);

  const sortByTime = (roomA, roomB) => {
    const roomATime = new Date(roomA.props.time).getTime();
    const roomBTime = new Date(roomB.props.time).getTime();

    if (roomATime > roomBTime) {
      return -1;
    }
    if (roomATime < roomBTime) {
      return 1;
    }
    return 0;
  };

  const sortByName = (roomA, roomB) => {
    const roomAName = roomA.props.room.name.indexOf(searchString);
    const roomBName = roomB.props.room.name.indexOf(searchString);

    if (roomAName > roomBName) {
      return 1;
    }
    if (roomAName < roomBName) {
      return -1;
    }
    return 0;
  };

  const sortFunc = searchString === '' ? sortByTime : sortByName;

  listRooms.sort(sortFunc);

  if (listRooms.length === 0 && searchString !== '') {
    return (
      <div className="sidebar__empty-search-list">No rooms found</div>
    );
  }

  return (
    <div className="sidebar__room-list">
      <Scrollbars autoHide>
        <ul className="room-list">{listRooms}</ul>
      </Scrollbars>
    </div>
  );
};

UserRoomsList.propTypes = {
  setRoomListVisibility: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
  setSearchString: PropTypes.func.isRequired,
};

export default UserRoomsList;
