import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './Main.css';
import Room from '../Room';
import * as parentAPI from '../../api/ParentAPI';
import * as chatAPI from '../../api/ChatAPI';
import Loading from '../Loading';
import actions from '../../actions';
import CreateRoom from '../CreateRoom';
import config from '../../config';
import ConfirmModalWindow from '../Common/ConfirmModalWindow';
import SideBar from '../SideBar';
import StartPrivate from '../StartPrivate';

export default () => {
  const [isRoomListVisible, setRoomListVisibility] = useState(true);
  const [isCreateRoomVisible, setCreateRoomVisibility] = useState(false);
  const [isStartPrivateVisible, setStartPrivateVisibility] = useState(false);
  const [isLoadingVisible, setLoadingVisibility] = useState(true);
  const dispatch = useDispatch();

  const toggleCreateRoom = () => setCreateRoomVisibility((prevState) => !prevState);
  const toggleStartPrivate = () => setStartPrivateVisibility((prevState) => !prevState);

  const onNewRoom = (data) => {
    if (!data.args) {
      console.error(new Error('invalid args'));
      return;
    }
    const { args } = data;
    args.name = args.id;
    dispatch({
      type: actions.INVITED_TO_ROOM,
      payload: args,
    });
    parentAPI.sendNewEvent(data.cmd);
  };

  const onNewMessage = (data) => {
    const { args } = data;

    if (!args) {
      console.error(new Error('invalid args'));
      return;
    }

    dispatch(
      {
        type: actions.MESSAGE_RECEIVED,
        payload: args,
      },
    );

    parentAPI.sendNewEvent(data.cmd);
  };

  const onUsersDeletedFromRoom = (data) => {
    if (!data.args) {
      console.error(new Error('invalid args'));
      return;
    }
    const { users, roomId } = data.args;
    dispatch({
      type: actions.USERS_DELETED,
      payload: {
        users,
        roomId,
      },
    });
    parentAPI.sendNewEvent(data.cmd);
  };

  const onUsersAddedToRoom = (data) => {
    if (!data.args || !data.args.members) {
      console.error(new Error('invalid args'));
      return;
    }

    const {
      id, name, creator, members, type, readOnly, moderable,
    } = data.args;
    dispatch({
      type: actions.ROOM_SETTINGS_UPDATED,
      payload: {
        id,
        room: {
          name,
          type,
          members,
          creator,
          readOnly,
          moderable,
        },
      },
    });
    parentAPI.sendNewEvent(data.cmd);
  };

  const onApprovedMsg = (data) => {
    if (!data.args) {
      console.error('invalid args');
      return;
    }
    const { message, oldId } = data.args;
    dispatch({
      type: actions.APPROVED_MESSAGE,
      payload: {
        oldId,
        message,
      },
    });
    parentAPI.sendNewEvent(data.cmd);
  };

  const onRoomSettingsUpdated = (data) => {
    if (!data.args) {
      console.error(new Error('invalid args'));
      return;
    }
    const {
      id, name, creator, members, type, readOnly, moderable,
    } = data.args;
    dispatch({
      type: actions.ROOM_SETTINGS_UPDATED,
      payload: {
        id,
        room: {
          name,
          type,
          members,
          creator,
          readOnly,
          moderable,
        },
      },
    });
    parentAPI.sendNewEvent(data.cmd);
  };

  const onClearHistory = (data) => {
    if (!data.args) {
      console.error(new Error('invalid args'));
      return;
    }
    const { roomId } = data.args;
    dispatch({
      type: actions.UPDATE_ROOM_SETTINGS_STORE,
      payload: {
        id: roomId,
        room: {
          latestMsg: null,
          oldestMsgId: 0,
          unreadMsgCount: 0,
        },
      },
    });
    dispatch({
      type: actions.CLEAR_HISTORY_STORE,
      payload: {
        roomId,
      },
    });
  };

  const onNextUnreadId = (data) => {
    const { args } = data;

    if (!args) {
      console.error(new Error('invalid args'));
      return;
    }

    dispatch({
      type: actions.NEXT_UNREAD_ID,
      payload: {
        ...args,
      },
    });
  };

  const onModMessageRejected = (data) => {
    const { args } = data;

    if (!args) {
      console.error(new Error('invalid args'));
      return;
    }

    dispatch(
      {
        type: actions.MOD_MESSAGE_REJECTED,
        payload: {
          ...args,
        },
      },
    );
  };

  const onDeletedRoom = (data) => {
    const { args } = data;

    if (!args) {
      console.error(new Error('invalid args'));
      return;
    }

    dispatch(
      {
        type: actions.ROOM_DELETED,
        payload: {
          roomId: args.roomId,
        },
      },
    );

    parentAPI.sendNewEvent(data.cmd);
  };

  const onQuitRoom = (data) => {
    const { args } = data;

    if (!args) {
      console.error(new Error('invalid args'));
      return;
    }

    dispatch(
      {
        type: actions.USERS_DELETED,
        payload: {
          roomId: args.roomId,
        },
      },
    );

    parentAPI.sendNewEvent(data.cmd);
  };

  const setup = () => {
    chatAPI.onConnected(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const userID = urlParams.get('userID');
      dispatch({ type: actions.SET_USERINFO, payload: { username: userID, isGuest: false } });
      chatAPI.authConnection({
        username: userID,
      }).then(() => {
        setLoadingVisibility(false);
      }).then(() => {
        dispatch({ type: actions.GET_USER_ROOMS });
      });
    });
    chatAPI.onDisconnected(() => {
      console.log('there will be your callback');
    });
    chatAPI.onNewRoom(onNewRoom);
    chatAPI.onMsg(onNewMessage);
    chatAPI.onNextUnreadId(onNextUnreadId);
    chatAPI.onUsersDeletedFromRoom(onUsersDeletedFromRoom);
    chatAPI.onUsersAddedToRoom(onUsersAddedToRoom);
    chatAPI.onApprovedMsg(onApprovedMsg);
    chatAPI.onRoomSettingsUpdated(onRoomSettingsUpdated);
    chatAPI.onClearHistory(onClearHistory);
    chatAPI.onModMessageRejected(onModMessageRejected);
    chatAPI.onDeletedRoom(onDeletedRoom);
    chatAPI.onQuitRoom(onQuitRoom);
    chatAPI.start({
      url: config.backendWebSocket,
      pingMsg: config.wsPingMsg,
      pingWait: config.wsPingWait,
      pingInterval: config.wsPingInterval,
      reconnectDelay: config.wsReconnectDelay,
    });
  };

  useEffect(() => setup(), []);

  return (
    <>
      {isLoadingVisible && <Loading />}
      {isCreateRoomVisible && <CreateRoom closeCb={toggleCreateRoom} />}
      {isStartPrivateVisible && <StartPrivate closeCb={toggleStartPrivate} />}
      <ConfirmModalWindow />
      <div className={`sidebar ${isRoomListVisible ? 'sidebar_visible' : ''}`}>
        <SideBar
          setRoomListVisibility={setRoomListVisibility}
          toggleCreateRoom={toggleCreateRoom}
          toggleStartPrivate={toggleStartPrivate}
        />
      </div>
      <div className="content">
        <Room setRoomListVisibility={setRoomListVisibility} />
      </div>
    </>
  );
};
