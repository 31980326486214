import actions from '../actions';

const roomsInitialState = {
  activeRoomID: '',
  rooms: {},
};

// room store structure
// {
//   activeRoomID: 'room-id-string',
//   rooms: {
//     'room-id-string': {
//       name: 'room name',
//       members: ['user1', 'user2'],
//       creator: 'user1',
//       type: 'group'/'private',
//       readOnly: true/false ,
//       latestMsg: {
//         sender: 'user2',
//         text: 'some text',
//         id: 3,
//         time: '2014-01-01T23:28:56.782Z',
//       },
//       oldestMsgId: 1,
//       unreadMsgCount: 1 ,
//     }
//   }
// }

export default (state = roomsInitialState, action) => {
  switch (action.type) {
  case actions.SET_ACTIVE_ROOM: {
    return {
      ...state,
      activeRoomID: action.payload,
    };
  }
  case actions.GET_USER_ROOMS_STORE: {
    const { rooms: oldRooms } = state;
    const { rooms } = action.payload;
    return {
      ...state,
      rooms: { ...oldRooms, ...rooms },
    };
  }
  case actions.DELETE_USERS_FROM_ROOM_STORE:
  case actions.USERS_DELETED_STORE: {
    const { users, roomId } = action.payload;
    const { rooms } = state;
    rooms[roomId].members = rooms[roomId].members.filter((member) => !users.includes(member));
    return {
      ...state,
      rooms: { ...rooms },
    };
  }
  case actions.CREATE_ROOM_STORE: {
    const { rooms } = state;
    const {
      id,
      name,
      members,
      creator,
      type,
      readOnly,
      latestMsg,
      moderable,
      oldestMsgId,
      unreadMsgCount,
    } = action.payload;

    rooms[id] = {
      name,
      members,
      creator,
      type,
      readOnly,
      latestMsg,
      moderable,
      oldestMsgId,
      unreadMsgCount,
    };
    return {
      ...state,
      activeRoomID: id,
      rooms: { ...rooms },
    };
  }
  case actions.INVITED_TO_ROOM_STORE:
  case actions.USERS_ADDED:
  case actions.UPDATE_ROOM: {
    const { rooms } = state;
    const {
      id,
      name,
      members,
      creator,
      type,
      readOnly,
      latestMsg,
      moderable,
      oldestMsgId,
      unreadMsgCount,
      botActivity,
    } = action.payload;
    rooms[id] = {
      name,
      members,
      creator,
      type,
      readOnly,
      latestMsg,
      moderable,
      oldestMsgId,
      unreadMsgCount,
      botActivity,
    };
    return {
      ...state,
      rooms: { ...rooms },
    };
  }
  case actions.UPDATE_ROOM_SETTINGS_STORE:
  case actions.ROOM_SETTINGS_UPDATED: {
    const { rooms } = state;
    const {
      id,
      room,
    } = action.payload;
    rooms[id] = {
      ...rooms[id],
      ...room,
    };
    return {
      ...state,
      rooms: { ...rooms },
    };
  }
  case actions.ROOM_LATEST_MESSAGE: {
    const { rooms } = state;
    const { roomId, latestMsg } = action.payload;
    return {
      ...state,
      rooms: {
        ...rooms,
        [roomId]: {
          ...rooms[roomId],
          latestMsg,
        },
      },
    };
  }
  case actions.DELETE_ROOM_STORE: {
    const { rooms } = state;
    const { roomId } = action.payload;
    delete rooms[roomId];

    return {
      ...state,
      rooms: { ...rooms },
    };
  }
  default:
    return state;
  }
};
