import { call, put } from 'redux-saga/effects';
import actions from '../actions';
import * as chatAPI from '../api/ChatAPI';

export default function* createRoom(a) {
  const {
    name, members, readOnly, moderable,
  } = a.payload;
  if (!Array.isArray(members) || members.length === 0) {
    console.error(new Error('invalid members param'));
    return;
  }
  if (typeof name !== 'string' || name.length === 0) {
    console.error(new Error('invalid roomName param'));
    return;
  }
  if (typeof readOnly !== 'boolean') {
    console.error(new Error('invalid readOnly param'));
    return;
  }
  if (typeof moderable !== 'boolean') {
    console.error(new Error('invalid moderable param'));
    return;
  }
  try {
    const room = yield call(chatAPI.createRoom, {
      name,
      members,
      readOnly,
      moderable,
    });
    if (!Array.isArray(room.members) || room.members.length === 0) {
      console.error(new Error('invalid members'));
      return;
    }
    if (typeof room.id !== 'string' || room.id.length === 0) {
      console.error(new Error('invalid id format'));
      return;
    }
    if (typeof room.name !== 'string' || room.name.length === 0) {
      console.error(new Error('invalid name'));
      return;
    }
    if (typeof room.readOnly !== 'boolean') {
      console.error(new Error('invalid readOnly'));
      return;
    }
    if (typeof room.moderable !== 'boolean') {
      console.error(new Error('invalid moderable'));
      return;
    }
    yield put({
      type: actions.CREATE_ROOM_STORE,
      payload: room,
    });
  } catch (e) {
    console.error(e);
  }
}
