import { put, select } from 'redux-saga/effects';
import { getMessages } from '../selectors';
import actions from '../actions';


export default function* approvedMessage(a) {
  const { message = {}, oldId } = a.payload;
  const {
    roomId, sender,
  } = message;

  if (typeof oldId !== 'number' || oldId < 1) {
    console.error('approvedMessage saga: invalid oldId parameter');
    return;
  }
  if (typeof roomId !== 'string' || roomId.length === 0) {
    console.error('approvedMessage saga: invalid roomId parameter');
    return;
  }
  if (typeof sender !== 'string' || sender.length === 0) {
    console.error('approvedMessage saga: invalid sender parameter');
    return;
  }

  try {
    const messages = yield select(getMessages);
    const roomMessages = messages[roomId]?.messages;
    if (!roomMessages) return;
    messages[roomId].messages = [message, ...roomMessages]
      .filter((msg) => msg.id !== oldId);
    yield put({
      type: actions.SET_MESSAGES_STORE,
      payload: messages,
    });
    yield put({
      type: actions.CHAT_UPDATED,
      payload: {
        client: sender,
        roomId,
      },
    });
    yield put({
      type: actions.UPDATE_ROOM_SETTINGS_STORE,
      payload: {
        id: roomId,
        room: {
          latestMsg: message,
        },
      },
    });
  } catch (e) {
    console.log(e);
  }
}
