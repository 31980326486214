/* eslint-disable */
export default {
  debuglog: DEBUG_LOG,
  backendWebSocket: BACKEND_WEBSOCKET,
  backendRequestTimeout: 10000, /* Timeout for waiting request */
  originWhitelist: ORIGIN_DOMAIN_WHITELIST,
  userSearchMaxResultCount: 20, /* limited count users in request */
  userSearchInputDelay: 300, /* Delay before showing user list */
  WaivioApi: 'https://waiviodev.com/api', /* url for Waivio request */
  avatarImageAPI: 'https://steemitimages.com',
  scrollbarHideTimeout: 750, /* Scrollbars setting */
  scrollbarHideDuration: 150, /* Scrollbars setting */
  messageHistoryQueryCount: 50, /* number of requested messages */
  systemUsername: 'ede8c0ee-a08d-45fb-bb69-000bcf070c9e',
  sendingSymbolsCount: 5000,
  wsPingWait: 120, /*ping wait time in seconds (max - 120)*/
  wsPingMsg: '.', /*max - 4 byte*/
  wsPingInterval: 40000, /* time in milliseconds min - 1 */
  wsReconnectDelay: 10 /*reconnect delay in seconds, min - 1 */
};
