import { call, put, select } from 'redux-saga/effects';
import { getRooms } from '../selectors';
import * as chatAPI from '../api/ChatAPI';
import actions from '../actions';

export default function* changeBotActivity(a) {
  const { roomId } = a.payload;
  try {
    const rooms = yield select(getRooms);
    const room = rooms[roomId];
    if (!room) return;
    const botActivity = !room.botActivity;
    yield call(chatAPI.changeBotActivity, roomId, botActivity);

    yield put({
      type: actions.UPDATE_ROOM_SETTINGS_STORE,
      payload: {
        id: roomId,
        room: {
          botActivity,
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
}
