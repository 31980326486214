import { call, put } from 'redux-saga/effects';
import actions from '../actions';
import * as chatAPI from '../api/ChatAPI';

export default function* deleteUsersFromRoom(a) {
  const { users, roomId } = a.payload;
  if (!Array.isArray(users) || users.length === 0) {
    console.log(new Error('invalid users param'));
    return;
  }
  if (typeof roomId !== 'string' || roomId.length === 0) {
    console.log(new Error('invalid roomId param'));
    return;
  }
  try {
    yield call(chatAPI.deleteUsersFromRoom, users, roomId);
    yield put({
      type: actions.DELETE_USERS_FROM_ROOM_STORE,
      payload: {
        users,
        roomId,
      },
    });
  } catch (e) {
    console.log(e);
  }
}
