import React from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import './confirmModalWindow.css';
import actions from '../../../actions';

const ConfirmModalWindow = () => {
  const { msg, enabled, resultCb } = useSelector((state) => state.modals.confirm);
  const dispatch = useDispatch();


  const onConfirm = () => {
    resultCb(true);
    dispatch({ type: actions.CLOSE_CONFIRM_MODAL });
  };

  const onCancel = () => {
    resultCb(false);
    dispatch({ type: actions.CLOSE_CONFIRM_MODAL });
  };

  if (!enabled) {
    return <></>;
  }
  return (
    ReactDOM.createPortal(
      <div className="modal__blocker">
        <div className="modal__window">
          <div className="modal__header">Confirmation</div>
          <div className="modal__msg">{msg}</div>
          <div className="modal__buttons">
            <div onClick={onConfirm} className="modal__btn">Yes</div>
            <div onClick={onCancel} className="modal__btn">No</div>
          </div>
        </div>

      </div>,
      document.getElementById('modal'),
    )
  );
};

export default ConfirmModalWindow;
