import WsConnector from './wsConnector';

// ChatAPI is used for data interaction between BE and FE over websocket.
// If response is valid, it should return response args (!!!) which contains appropriate data
// It uses custom event protocol. To figure out details about the protocol, follow the link below.
// https://maxiproject.atlassian.net/wiki/spaces/GOP/pages/1517420603/Protocol+STChat+iFrame+-+BE

const wsConnector = new WsConnector();

export const start = (params) => {
  wsConnector.start(params);
};

export const onConnected = (connectedCb) => {
  wsConnector.onConnected(connectedCb);
};

export const onDisconnected = (disconnectedCb) => {
  wsConnector.onDisconnected(disconnectedCb);
};

// createRoom sends message with create_room command and room in args to BE
export const createRoom = (room) => wsConnector.requestProcessing({
  requestCmd: 'create_room',
  requestArgs: room,
  responseCmd: 'create_room_response',
});

// addUsersToRoom sends message with add_users_to_room command, users and room id in args to BE
export const addUsersToRoom = (users, roomId) => wsConnector.requestProcessing({
  requestCmd: 'add_users_to_room',
  requestArgs: {
    users,
    roomId,
  },
  responseCmd: 'add_users_to_room_response',
});

// deleteUsersFromRoom sends message with delete_users_from_room command,
// users and room id in args to BE
export const deleteUsersFromRoom = (users, roomId) => wsConnector.requestProcessing({
  requestCmd: 'delete_users_from_room',
  requestArgs: {
    users,
    roomId,
  },
  responseCmd: 'delete_users_from_room_response',
});

// getUserRooms sends message with get_user_rooms command to BE
export const getUserRooms = () => wsConnector.requestProcessing({
  requestCmd: 'get_user_rooms',
  requestArgs: null,
  responseCmd: 'get_user_rooms_response',
});

// getRoomMessages sends message with get_room_messages command,
// count, room id and time in args to BE
export const getRoomMessages = (roomId, msgId, count) => wsConnector.requestProcessing({
  requestCmd: 'get_room_messages',
  requestArgs: {
    roomId,
    msgId,
    count,
  },
  responseCmd: 'get_room_messages_response',
});

// getRoomModMessages sends message with get_room_mod_messages command,
// count, room id and time in args to BE
export const getRoomModMessages = (roomId, msgId, count) => wsConnector.requestProcessing({
  requestCmd: 'get_room_mod_messages',
  requestArgs: {
    roomId,
    msgId,
    count,
  },
  responseCmd: 'get_room_mod_messages_response',
});

// sendMessage sends message with new_message command to BE
export const sendMessage = (message) => wsConnector.requestProcessing({
  requestCmd: 'new_message',
  requestArgs: message,
  responseCmd: 'new_message_response',
});

// sendModMessage sends message with new_mod_message command to BE
export const sendModMessage = (message) => wsConnector.requestProcessing({
  requestCmd: 'new_mod_message',
  requestArgs: message,
  responseCmd: 'new_mod_message_response',
});

// getSessionId sends message with get_session_id command to BE
export const getSessionId = () => wsConnector.requestProcessing({
  requestCmd: 'get_session_id',
  requestArgs: null,
  responseCmd: 'get_session_id_response',
});

// authConnection sends message with auth_connection command and auth data in args to BE
export const authConnection = (authData) => wsConnector.requestProcessing({
  requestCmd: 'auth_connection',
  requestArgs: authData,
  responseCmd: 'auth_connection_response',
});

// startChat sends message with start_chat command and partner in args to BE
export const startChat = (partner) => wsConnector.requestProcessing({
  requestCmd: 'start_chat',
  requestArgs: {
    partner,
  },
  responseCmd: 'start_chat_response',
});

// updateRoomSettings sends message with update_room_settings command
// and updated room settings in args to BE
export const updateRoomSettings = (roomSettings) => wsConnector.requestProcessing({
  requestCmd: 'update_room_settings',
  requestArgs: roomSettings,
  responseCmd: 'update_room_settings_response',
});

// nextUnreadMessage sends message with next_unread_message command
// and message id with room_id in args to BE
export const nextUnreadMessage = (roomId, msgId) => wsConnector.requestProcessing({
  requestCmd: 'next_unread_message',
  requestArgs: {
    roomId,
    messageId: msgId,
  },
  responseCmd: 'next_unread_message_response',
});

// clearHistory sends message with clear_history command
// and room id args to BE
export const clearHistory = (roomId) => wsConnector.requestProcessing({
  requestCmd: 'clear_history',
  requestArgs: {
    roomId,
  },
  responseCmd: 'clear_history_response',
});

// getUnreadMessages sends message with get_room_unread_messages command
// and roomId, fromMessageId, count args to BE
export const getRoomUnreadMessages = (roomId, fromMessageId, count) => wsConnector.requestProcessing({
  requestCmd: 'get_room_unread_messages',
  requestArgs: {
    roomId,
    fromMessageId,
    count,
  },
  responseCmd: 'get_room_unread_messages_response',
});

// getRoomUnreadModMessages sends message with get_room_unread_mod_messages command
// and roomId, fromMessageId, count args to BE
export const getRoomUnreadModMessages = (roomId, fromMessageId, count) => wsConnector.requestProcessing({
  requestCmd: 'get_room_unread_mod_messages',
  requestArgs: {
    roomId,
    fromMessageId,
    count,
  },
  responseCmd: 'get_room_unread_mod_messages_response',
});

// approveModMessage sends message with approve_mod_message command
// and messageId, roomId args to BE
export const approveModMessage = (messageId, roomId) => wsConnector.requestProcessing({
  requestCmd: 'approve_mod_message',
  requestArgs: {
    roomId,
    messageId,
  },
  responseCmd: 'approve_mod_message_response',
});

// deleteModMessage sends message with reject_mod_message command
// and msgId, roomId args to BE
export const rejectModMessage = (msgId, roomId) => wsConnector.requestProcessing({
  requestCmd: 'reject_mod_message',
  requestArgs: {
    roomId,
    msgId,
  },
  responseCmd: 'reject_mod_message_response',
});

// deleteRoom sends message with delete_room command
// and room id args to BE
export const deleteRoom = (roomId) => wsConnector.requestProcessing({
  requestCmd: 'delete_room',
  requestArgs: {
    roomId,
  },
  responseCmd: 'delete_room_response',
});

// quitRoom sends message with quit_room command
// and room id args to BE
export const quitRoom = (roomId) => wsConnector.requestProcessing({
  requestCmd: 'quit_room',
  requestArgs: {
    roomId,
  },
  responseCmd: 'quit_room_response',
});

export const changeBotActivity = (roomId, botActivity) => wsConnector.requestProcessing({
  requestCmd: 'change_bot_activity',
  requestArgs: {
    roomId,
    botActivity,
  },
  responseCmd: 'change_bot_activity_response',
});

// onMsg registers callback for new_message message
export const onMsg = (callback) => {
  wsConnector.register('new_message', callback);
};

// onNewRoom registers callback for new_room message
export const onNewRoom = (callback) => {
  wsConnector.register('new_room', callback);
};

// onUsersAddedToRoom registers callback for add_users_to_room message
export const onUsersAddedToRoom = (callback) => {
  wsConnector.register('add_users_to_room', callback);
};

// onUsersDeletedFromRoom registers callback for delete_users_from_room message
export const onUsersDeletedFromRoom = (callback) => {
  wsConnector.register('delete_users_from_room', callback);
};

// onUsersDeletedFromRoom registers callback for room_settings_updated message
export const onRoomSettingsUpdated = (callback) => {
  wsConnector.register('room_settings_updated', callback);
};

// onClearHistory registers callback for clear_history_event message
export const onClearHistory = (callback) => {
  wsConnector.register('clear_history_event', callback);
};

// onNextUnreadId registers callback for next_unread_id_event message
export const onNextUnreadId = (callback) => {
  wsConnector.register('next_unread_id_event', callback);
};

// onModMessageDeleted registers callback for reject_mod_message message
export const onModMessageRejected = (callback) => {
  wsConnector.register('reject_mod_message', callback);
};

// onApprovedMsg registers callback for approved_msg message
export const onApprovedMsg = (callback) => {
  wsConnector.register('approved_msg', callback);
};

// onRoomDeleted registers callback for delete_room message
export const onDeletedRoom = (callback) => {
  wsConnector.register('deleted_room', callback);
};

export const onQuitRoom = (callback) => {
  wsConnector.register('quit_room', callback);
};
