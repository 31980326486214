import { call, select, put } from 'redux-saga/effects';
import * as chatAPI from '../api/ChatAPI';
import { getMessages } from '../selectors';
import actions from '../actions';

export default function* rejectModMessage(a) {
  try {
    console.log('rejectModMessage', a);
    const { id, roomId } = a.payload;
    if (typeof id !== 'number' || id < 1) {
      console.error('rejectModMessage saga: invalid id parameter');
      return;
    }
    if (typeof roomId !== 'string' || roomId.length === 0) {
      console.error('rejectModMessage saga: invalid roomId parameter');
      return;
    }

    yield call(chatAPI.rejectModMessage, id, roomId);

    const messagesState = yield select(getMessages);
    const { messages } = messagesState[roomId] || {};
    if (!messages) {
      console.error('rejectModMessage saga: roomMessages isn`t existed');
      return;
    }

    const msgIndex = messages.findIndex((msg) => msg.id === id);
    if (msgIndex === -1) {
      console.error('rejectModMessage saga: required message isn`t existed');
      return;
    }

    messages[msgIndex].isModerated = 0;

    messagesState[roomId] = {
      ...messagesState[roomId], messages,
    };

    yield put({
      type: actions.SET_MESSAGES_STORE,
      payload: messagesState,
    });
  } catch (e) {
    console.error(e);
  }
}
