
export default (url) => new Promise((resolve, reject) => {
  fetch(url)
    .then((response) => {
      if (response.ok !== true) reject(Error(`Request failed: response code ${response.status}`));
      return response.json();
    })
    .then((data) => {
      resolve(data);
    })
    .catch((err) => reject(err));
});
