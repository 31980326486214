import ResponseRouter from './ResponseRouter';
import config from '../../config';

// WsConnector is used for sending messages to BE by websocket
export default class WsConnector {
  constructor() {
    this.url = null;
    this.ws = null;
    this.connectLoop = null;
    this.connected = () => {
    };
    this.disconnected = () => {
    };
    this.responseRouter = new ResponseRouter();
  }

  connect(params) {
    const {
      url,
      pingMsg,
      pingWait,
      pingInterval,
    } = params;
    const newUrl = url.concat('?pingMsg=', pingMsg, '&pingWait=', pingWait);
    return new Promise((resolve, reject) => {
      this.ws = new WebSocket(newUrl);
      window.addEventListener('beforeunload', () => { this.ws.close(); });
      this.ws.onerror = () => {
        reject();
        this.ws.onerror = undefined;
      };
      this.ws.onopen = () => {
        console.log('connected');
        resolve();
        this.ws.onmessage = (event) => {
          this.responseRouter.onMessage(event.data);
        };
        this.ws.onclose = () => {
          clearInterval(this.pingInterval);
          console.log('disconnected');
          this.ws.close();
          this.disconnected();
          this.start(params);
        };
        this.connected();
        this.pingInterval = setInterval(() => {
          this.ws.send(pingMsg);
        }, pingInterval);
      };
    });
  }

  start(params) {
    const { reconnectDelay } = params;
    const interval = Math.floor(Math.random() * (1001) + reconnectDelay * 1000);
    this.connect(params).catch(() => {
      this.connectLoop = setInterval(() => {
        this.connect(params)
          .then(() => {
            clearInterval(this.connectLoop);
          });
      }, interval);
    });
  }

  onConnected(connectedCb) {
    this.connected = connectedCb;
  }

  onDisconnected(disconnectedCb) {
    this.disconnected = disconnectedCb;
  }

  // requestProcessing serializes message and sends it to BE
  // returns 2 promises
  // should reject if responseRouter or BE returns error or processing is finished by timeout
  // should resolve if BE returns valid response
  // params = { requestCmd, requestArgs, responseCmd }
  requestProcessing(params) {
    const { requestCmd, requestArgs, responseCmd } = params;
    if (this.ws.readyState !== 1) {
      return Promise.reject(new Error('Websocket isn\'t connected'));
    }
    const request = new Promise((resolve, reject) => {
      try {
        this.responseRouter.register(responseCmd, (data) => {
          if (data.error) {
            reject(new Error(`message with cmd: ${data.cmd} contains error: ${data.error}`));
            this.responseRouter.unregister(responseCmd);
            return;
          }
          resolve(data.args);
          this.responseRouter.unregister(responseCmd);
        });
        const req = JSON.stringify({
          cmd: requestCmd,
          args: requestArgs,
        });
        this.ws.send(req);
      } catch (e) {
        reject(new Error(`cmd "${requestCmd}" processing has been finished with error ${e}`));
        this.responseRouter.unregister(responseCmd);
      }
    });

    const timer = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error(`cmd "${requestCmd}" processing has been finished by timeout`));
      }, config.backendRequestTimeout);
    });
    return Promise.race([request, timer]);
  }

  register(cmd, callback) {
    this.responseRouter.register(cmd, callback);
  }
}
