import { call, put } from 'redux-saga/effects';
import * as chatAPI from '../api/ChatAPI';
import actions from '../actions';

export default function* clearHistory(a) {
  const { roomId } = a.payload;
  if (typeof roomId !== 'string' || roomId.length === 0) {
    console.log(new Error('invalid roomId param'));
    return;
  }
  try {
    yield call(chatAPI.clearHistory, roomId);
    yield put({
      type: actions.UPDATE_ROOM_SETTINGS_STORE,
      payload: {
        id: roomId,
        room: {
          latestMsg: null,
          oldestMsgId: 0,
          unreadMsgCount: 0,
        },
      },
    });
    yield put({
      type: actions.CLEAR_HISTORY_STORE,
      payload: {
        roomId,
      },
    });
  } catch (e) {
    console.log(e);
  }
}
