import { put, select } from 'redux-saga/effects';
import actions from '../actions';
import { getUsername } from '../selectors';

const errorStr = 'invitedToRoom saga: payload has';
const isNotValid = 'field which is not valid';

const validateRoomFields = (room) => {
  if (typeof room.id !== 'string' || room.id.length === 0) {
    throw new Error(`${errorStr} "id" ${isNotValid}`);
  }
  if (typeof room.name !== 'string' || room.name.length === 0) {
    throw new Error(`${errorStr} "name" ${isNotValid}`);
  }
  if (!Array.isArray(room.members) || room.members.length === 0) {
    throw new Error(`${errorStr} "members" ${isNotValid}`);
  }
  if (typeof room.creator !== 'string' || room.creator.length === 0) {
    throw new Error(`${errorStr} "creator" ${isNotValid}`);
  }
  if (typeof room.unreadMsgCount !== 'number' || room.unreadMsgCount < 0) {
    throw new Error(`${errorStr} "unreadMsgCount" ${isNotValid}`);
  }
  if (typeof room.oldestMsgId !== 'number' || room.oldestMsgId < 0) {
    throw new Error(`${errorStr} "oldestMsgId" ${isNotValid}`);
  }
  if (typeof room.readOnly !== 'boolean') {
    throw new Error(`${errorStr} "readOnly" ${isNotValid}`);
  }
  if (typeof room.moderable !== 'boolean') {
    throw new Error(`${errorStr} "moderable" ${isNotValid}`);
  }
};

export default function* (a) {
  try {
    const args = a.payload;
    validateRoomFields(args);
    const username = yield select(getUsername);
    console.log(`onnewroom: ${username}`);
    if (args.type === 'private') {
      args.members.forEach((member) => {
        if (username !== member) {
          args.name = member;
        }
      });
    }
    yield put({
      type: actions.INVITED_TO_ROOM_STORE,
      payload: args,
    });
  } catch (e) {
    console.error(e);
  }
}
