import actions from '../actions';

const chatMessagesUpdaterInitialState = {
  roomId: '',
  clientId: '',
};

export default (state = chatMessagesUpdaterInitialState, action) => {
  switch (action.type) {
    case actions.CHAT_UPDATED: {
      const { roomId, clientId } = action.payload;
      return {
        ...state,
        roomId,
        clientId,
      };
    }
    default:
      return state;
  }
};
