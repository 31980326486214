import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import RoomHeader from './RoomHeader';
import ChatMessages from './ChatMessages';
import './Room.css';
import SendBox from './SendBox';
import ErrorBoundary from '../Common/ErrorBoundary';
import RoomPlaceholder from './RoomPlaceholder';

const Room = ({ setRoomListVisibility }) => {
  const { activeRoomID, rooms } = useSelector((state) => state.roomsReducer);
  const { username } = useSelector((state) => state.userInfoReducer);

  if (Object.keys(rooms).length === 0) {
    const text = 'There are no any rooms yet. You can create one by pressing plus on top of sidebar.';
    return <RoomPlaceholder text={text} />;
  }

  if (activeRoomID === '') {
    const text = 'Select a room to view it conversation.';
    setRoomListVisibility(true);
    return <RoomPlaceholder text={text} />;
  }
  const activeRoom = rooms[activeRoomID];

  return (
    <div className="room">
      <RoomHeader
        setRoomListVisibility={setRoomListVisibility}
        roomId={activeRoomID}
        roomName={activeRoomID}
        isCreator={false}
        roomType="group"
        botActivity={activeRoom.botActivity}
      />
      <ErrorBoundary>
        <>
          <ChatMessages
            activeRoom={activeRoom}
            activeRoomID={activeRoomID}
            username={username}
          />
          <SendBox roomId={activeRoomID} />
        </>
      </ErrorBoundary>
    </div>
  );
};

Room.propTypes = {
  setRoomListVisibility: PropTypes.func.isRequired,
};

export default Room;
