import Fetcher from './Fetcher';
import config from '../../config';

const { WaivioApi } = config;

export const searchUser = (usernameExcerpt, maxEntries) => {
  if (typeof usernameExcerpt !== 'string' || usernameExcerpt.length === 0) {
    const errStr = 'usernameExcerpt isn\'t string type or have empty value';
    return Promise.reject(Error(errStr));
  }
  if (typeof maxEntries !== 'number' || maxEntries <= 0 || maxEntries > 100) {
    const errStr = 'maxEntries isn\'t number type or heave a negative, zero, bigger than 100 value';
    return Promise.reject(Error(errStr));
  }
  const urlWithParams = `${WaivioApi}/users/search?searchString=${usernameExcerpt}&limit=${maxEntries}`;
  return Fetcher(urlWithParams);
};

export const getUserDelails = (userID) => {
  if (typeof userID !== 'string' || userID.length === 0) {
    const errStr = 'userID isn\'t string type or have empty value';
    return Promise.reject(Error(errStr));
  }
  const urlWithParams = `${WaivioApi}/user/${userID}`;
  return Fetcher(urlWithParams);
};

export const getAvatar = (username) => `${config.avatarImageAPI}/u/${username}/avatar`;
