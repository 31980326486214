const DebounceDelayer = (callback, callDelay) => {
  if (typeof callback !== 'function') {
    console.log("callback isn't  a function type");
    return () => {};
  }

  if (typeof callDelay !== 'number' || callDelay <= 0) {
    console.log("callDelay isn't a number type or have value equal or less than 0");
    return () => {};
  }
  let timer;
  return (param) => {
    if (timer !== undefined) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      callback(param);
    }, callDelay);
  };
};

export default DebounceDelayer;
