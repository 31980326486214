import React from 'react';
import PropTypes from 'prop-types';
import './switch.css';

const Switch = (props) => {
  // eslint-disable-next-line react/prop-types
  const { startValue, switchCb } = props;
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="switch">
      <input type="checkbox" checked={startValue} onChange={(e) => { switchCb(e); }} />
      <span className="slider" />
    </label>
  );
};

Switch.propTypes = {
  switchCb: PropTypes.func.isRequired,
};

export default Switch;
