import { put, select } from 'redux-saga/effects';
import actions from '../actions';
import { getActiveRoomId, getUsername } from '../selectors';

export default function* usersDeletedFromRoom(a) {
  try {
    const { users, roomId } = a.payload;
    if (!Array.isArray(users) || users.length === 0) {
      console.error(new Error('invalid users param'));
      return;
    }
    if (typeof roomId !== 'string' || roomId.length === 0) {
      console.error(new Error('invalid roomId param'));
      return;
    }
    const username = yield select(getUsername);
    const activeRoom = yield select(getActiveRoomId);

    if (!users.includes(username)) {
      yield put({
        type: actions.DELETE_USERS_FROM_ROOM_STORE,
        payload: {
          users,
          roomId,
        },
      });
      return;
    }

    if (roomId === activeRoom) {
      yield put({
        type: actions.SET_ACTIVE_ROOM,
        payload: '',
      });
    }

    yield put(
      {
        type: actions.DELETE_ROOM_STORE,
        payload: {
          roomId,
        },
      },
    );
  } catch (e) {
    console.error(e);
  }
}
