import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdArrowBack } from 'react-icons/md';
import PropTypes from 'prop-types';
import EditList from '../Common/EditList';
import Search from '../Common/Search';
import actions from '../../actions';
import './AddUsersToRoom.css';

const AddUsersToRoom = ({ closeCb, roomName }) => {
  const { activeRoomID, rooms } = useSelector((state) => state.roomsReducer);
  const username = useSelector((state) => state.userInfoReducer.username);
  const dispatch = useDispatch();
  const { members, type } = rooms[activeRoomID];
  const membersList = type === 'group' ? [...members.filter((item) => item !== username)].reverse() : members;
  const headTitle = type === 'group' ? `Edit members of ${roomName}` : 'Add a group room';
  const showClose = type === 'group';

  const addUserToListCb = (name) => {
    if (name === '' || membersList.includes(name)) return;
    if (type === 'group') {
      dispatch({
        type: actions.ADD_USERS_TO_ROOM,
        payload: {
          users: [name],
          roomId: activeRoomID,
        },
      });
    } else {
      const newMembers = [...members, name];
      dispatch({
        type: actions.CREATE_ROOM,
        payload: {
          members: newMembers,
          name: newMembers.join(', '),
          readOnly: false,
          moderable: false,
        },
      });
      closeCb();
    }
  };
  const removeUserCb = (name) => {
    dispatch({
      type: actions.DELETE_USERS_FROM_ROOM,
      payload: {
        users: [name],
        roomId: activeRoomID,
      },
    });
  };

  return (
    <div className="add-room">
      <div className="add-room__header">
        <div
          className="room-header__btn"
          onClick={closeCb}
        >
          <MdArrowBack title="Back" />
        </div>
        <div className="add-room__header-text" title="Add members">
          <span>{headTitle}</span>
        </div>
      </div>
      <div className="add-room__body">
        <div className="add-room__search">
          <Search addUserToListCb={addUserToListCb} />
        </div>
        <EditList listAddedUsers={membersList} removeUserCb={removeUserCb} showClose={showClose} />
      </div>
    </div>
  );
};

AddUsersToRoom.propTypes = {
  closeCb: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
};

export default AddUsersToRoom;
