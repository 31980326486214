import { call, put } from 'redux-saga/effects';
import * as chatAPI from '../api/ChatAPI';
import actions from '../actions';


export default function* startPrivate(action) {
  const { partner = undefined } = action.payload;
  if (typeof partner !== 'string' || partner.length === 0) {
    console.error('startPrivate saga: partner param is invalid');
    return;
  }
  try {
    const room = yield call(chatAPI.startChat, partner);
    const { id } = room;
    yield put({
      type: actions.UPDATE_ROOM,
      payload: { ...room, name: partner },
    });
    yield put({
      type: actions.SET_ACTIVE_ROOM,
      payload: id,
    });
  } catch (e) {
    console.error(e.message);
  }
}
