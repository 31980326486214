import actions from '../actions';

const modalsInitialState = {
  confirm: {
    msg: '',
    enabled: false,
    resultCb: () => false,
  },
};

const modals = (state = modalsInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
  case actions.OPEN_CONFIRM_MODAL:
  {
    const { msg, resultCb } = payload;
    const enabled = true;
    return {
      ...state,
      confirm: {
        msg,
        enabled,
        resultCb,
      },
    };
  }
  case actions.CLOSE_CONFIRM_MODAL:
  {
    return {
      ...state,
      confirm: modalsInitialState.confirm,
    };
  }
  default:
    return state;
  }
};

export default modals;
