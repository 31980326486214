import { put, select } from 'redux-saga/effects';
import actions from '../actions';
import { getRooms } from '../selectors';


export default function* (a) {
  const { roomId, messageId } = a.payload;
  if (typeof roomId !== 'string' || roomId.length === 0) {
    console.error(new Error('invalid roomId parameter'));
    return;
  }
  if (typeof messageId !== 'number' || messageId < 0) {
    console.error(new Error('invalid messageId parameter'));
    return;
  }

  try {
    const rooms = yield select(getRooms);
    const room = rooms[roomId] || {};
    let { unreadMsgCount = 0 } = room;
    unreadMsgCount = messageId ? unreadMsgCount : 0;
    yield put({
      type: actions.UPDATE_ROOM_SETTINGS_STORE,
      payload: {
        id: roomId,
        room: {
          oldestMsgId: messageId,
          unreadMsgCount,
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
}
