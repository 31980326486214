import React from 'react';
import { useSelector } from 'react-redux';
import './Loading.css';
import userPic from '../../images/manager.png';


const Loading = () => {
  const { username } = useSelector((state) => state.userInfoReducer);

  return (
    <div className="loading__content">
      <div className="loading">
        <div className="user-info">
          { username
          && (
            <img
              src={userPic}
              alt="failed to load avatar"
            />
          ) }
          <div className="user-name">{username}</div>
        </div>
        <div className="preloader">
          <div className="preloader_content">
            <div className="preloader_first" />
            <div className="preloader_second" />
            <div className="preloader_third" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
