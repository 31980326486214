/* ResponseRouter receives message from BE and calls callback by cmd field.
Callbacks should be registered in ResponseRouter. */
export default class ResponseRouter {
  constructor() {
    this.callbacks = {};
  }

  // register sets callback in callbacks field by cmd key
  register(cmd, callback) {
    if (!cmd || typeof cmd !== 'string' || cmd.length === 0) { throw new Error('invalid cmd param'); }

    if (!callback || typeof callback !== 'function') { throw new Error('invalid callback param'); }

    this.callbacks[cmd] = callback;
  }

  // unregister sets stub function
  // If stub function is called, it will log that input cmd is unexpected
  unregister(cmd) {
    if (!cmd || typeof cmd !== 'string' || cmd.length === 0) {
      console.log('invalid cmd param');
      return;
    }
    if (!this.callbacks[cmd]) {
      console.log(`cmd ${cmd} hasn't been registered`);
      return;
    }
    this.callbacks[cmd] = () => {
      console.log('unexpected cmd calling');
    };
  }

  // onMessage is callback which is called when message from BE is received
  onMessage(msg) {
    const data = JSON.parse(msg);
    if (!data.cmd || typeof data.cmd !== 'string' || data.cmd.length === 0) {
      console.log('invalid cmd param');
      return;
    }
    this.call(data);
  }

  // call calls handle function from callbacks by cmd field
  call(data) {
    const cb = this.callbacks[data.cmd];
    if (!cb) {
      console.log('unexpected cmd calling');
      return;
    }
    cb(data);
  }
}
