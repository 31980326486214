import React from 'react';
import './UserRoomItem.css';
import PropTypes from 'prop-types';
import { FaPauseCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import dialogPic from '../../../../images/dialog.png';
import actions from '../../../../actions';

export const RoomType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  unreadMsgCount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  latestMsg: PropTypes.object,
});

const UserRoomItem = (props) => {
  const {
    room, isActive, roomId, setActiveRoom,
  } = props;
  const currentClassName = isActive ? 'room-list__item room-list__item_highlighted' : 'room-list__item';
  let lastsMsgText = '';
  let timeStr = '';
  const unreadMsgCount = (room.unreadMsgCount > 999)
    ? `${Math.floor(room.unreadMsgCount / 1000)}k` : room.unreadMsgCount;
  const unreadMsgClassName = (unreadMsgCount === 0) ? 'room-list__count-hide' : 'room-list__count';
  const userpic = dialogPic;

  if (room.latestMsg && room.latestMsg.time) {
    lastsMsgText = room.latestMsg.text;
    const timeDate = new Date(room.latestMsg.time);
    const minutes = timeDate.getMinutes() < 10 ? `0${timeDate.getMinutes()}` : timeDate.getMinutes();
    timeStr = `${timeDate.getHours()}:${minutes}`;
  }

  const dispatch = useDispatch();
  const changeBotActivity = () => {
    dispatch({
      type: actions.CHANGE_BOT_ACTIVITY,
      payload: {
        roomId,
      },
    });
  };

  return (
    <li className={currentClassName}>
      <div className="list__item-content" onClick={() => setActiveRoom(roomId)} title={room.name}>
        <div className="room-list__userpic">
          <img src={userpic} alt="" />
        </div>
        <div className="room-list__info">
          <div className="room-list__info-row">
            <div className="room-list__name">{room.name}</div>
            <div className="room-list__time">{timeStr}</div>
          </div>
          <div className="room-list__info-row">
            <div className="room-list__last-msg">{lastsMsgText}</div>
            {!room.botActivity
            && <div className="is_paused" onClick={changeBotActivity}><FaPauseCircle /></div>}
            {(unreadMsgCount !== 0)
            && <div className={unreadMsgClassName}>{unreadMsgCount}</div>}
          </div>
        </div>
      </div>
    </li>
  );
};

UserRoomItem.propTypes = {
  room: RoomType.isRequired,
  roomId: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  setActiveRoom: PropTypes.func.isRequired,
};

export default UserRoomItem;
