import { call, put, select } from 'redux-saga/effects';
import actions from '../actions';
import { getRooms } from '../selectors';
import * as chatAPI from '../api/ChatAPI';

export default function* updateRoomSettings(a) {
  const {
    id, name, readOnly, moderable,
  } = a.payload;
  if (typeof id !== 'string' || id.length === 0) {
    console.error(new Error('invalid id param'));
    return;
  }
  if (typeof name !== 'string' || name.length === 0) {
    console.error(new Error('invalid roomName param'));
    return;
  }
  if (typeof readOnly !== 'boolean') {
    console.error(new Error('invalid readOnly param'));
    return;
  }
  if (typeof moderable !== 'boolean') {
    console.error(new Error('invalid moderable param'));
    return;
  }
  try {
    const rooms = yield select(getRooms);
    if (name === rooms[id].name
      && readOnly === rooms[id].readOnly
      && moderable === rooms[id].moderable) {
      return;
    }
    yield call(chatAPI.updateRoomSettings, {
      id,
      name,
      readOnly,
      moderable,
    });
    yield put({
      type: actions.UPDATE_ROOM_SETTINGS_STORE,
      payload: {
        id,
        room: {
          name,
          readOnly,
          moderable,
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
}
