import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import VisibilitySensor from 'react-visibility-sensor';
import Message from './Message';
import './ChatMessages.css';
import actions from '../../../actions';
import config from '../../../config';
import { emptyRoom } from '../../../reducers/messages';


export const RoomType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  creator: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
});

const ChatMessages = (props) => {
  const { activeRoom, activeRoomID, username } = props;
  const { creator } = activeRoom;
  const { oldestMsgId, latestMsg } = activeRoom;
  const scrollRef = useRef(undefined);
  let lastScrollPos;
  let historyAlreadyFetched = false;
  let unreadAlreadyFetched = false;
  let maxId = 0;
  const room = useSelector((state) => state.messagesReducer[activeRoomID]);
  const lastUpdate = useSelector((state) => state.chatMessagesUpdater);
  const {
    messages,
    historyEndReached,
    chatScrollPosition,
    initialHistoryFetched,
    unreadEndReached,
  } = room || emptyRoom;

  const dispatch = useDispatch();

  useEffect(() => {
    if (messages) scrollRef.current.scrollTop(chatScrollPosition.scrollTop);
    if ((!messages && !initialHistoryFetched) || !historyEndReached) {
      let fromMsg = oldestMsgId > 0 ? oldestMsgId : latestMsg?.id;

      if (fromMsg === 0 && messages.length > 0) {
        fromMsg = messages[messages.length - 1].id;
      }

      if (fromMsg > 0) {
        dispatch({
          type: actions.GET_MESSAGES,
          payload: {
            roomId: activeRoomID,
            msgId: fromMsg,
            count: config.messageHistoryQueryCount,
          },
        });
      }
    }
  }, [activeRoomID]);

  useEffect(() => {
    const clientHeight = scrollRef.current.getClientHeight();
    const scrollHeight = scrollRef.current.getScrollHeight();
    const { roomId, clientId } = lastUpdate;
    if (
      activeRoomID === roomId
      && clientHeight === scrollHeight
      && clientId !== username
      && !unreadEndReached) {
      dispatch({
        type: actions.NEXT_UNREAD_MESSAGE,
        payload: {
          roomId: activeRoomID,
          msgId: maxId,
        },
      });
    }
    if (roomId === activeRoomID) {
      if (chatScrollPosition.top >= 0.98) {
        scrollRef.current.scrollTop(scrollHeight - clientHeight);
      } else if (clientId === username) {
        scrollRef.current.scrollTop(scrollHeight - clientHeight);
      } else {
        scrollRef.current.scrollTop(chatScrollPosition.scrollTop);
      }
    }
  }, [lastUpdate]);

  useEffect(() => {
    const clientHeight = scrollRef.current.getClientHeight();
    const scrollHeight = scrollRef.current.getScrollHeight();
    const latestStoreId = messages[0]?.id || 0;
    const latestRoomId = latestMsg?.id || 0;
    if (initialHistoryFetched && latestStoreId < latestRoomId) {
      let fromMsg = latestStoreId > 0 ? latestStoreId : oldestMsgId;

      if (fromMsg === 0 && messages.length > 0) {
        fromMsg = messages[0].id;
      }

      dispatch({
        type: actions.GET_ROOM_UNREAD_MESSAGES,
        payload: {
          roomId: activeRoomID,
          msgId: fromMsg,
          count: 1000,
        },
      });
    }
    if (initialHistoryFetched && latestStoreId === latestRoomId && clientHeight === scrollHeight) {
      dispatch({
        type: actions.NEXT_UNREAD_MESSAGE,
        payload: {
          roomId: activeRoomID,
          msgId: 0,
        },
      });
    }
  }, [initialHistoryFetched, messages]);


  useEffect(() => {
    if (!initialHistoryFetched && ((messages && messages.length > 0) || historyEndReached)) {
      scrollRef.current.scrollToBottom();
      dispatch({
        type: actions.UPDATE_ROOM_SCROLL,
        payload: {
          chatScrollPosition: scrollRef.current.getValues(),
          roomId: activeRoomID,
        },
      });
      dispatch({
        type: actions.SET_INITIAL_HISTORY_FETCHED,
        payload: {
          roomId: activeRoomID,
          initialHistoryFetched: true,
        },
      });
    }
  }, [activeRoomID, messages]);

  useEffect(() => {
    if (unreadEndReached) {
      const clientHeight = scrollRef.current.getClientHeight();
      const scrollHeight = scrollRef.current.getScrollHeight();
      if (clientHeight === scrollHeight) {
        dispatch({
          type: actions.NEXT_UNREAD_MESSAGE,
          payload: {
            roomId: activeRoomID,
            msgId: 0,
          },
        });
      }
    }
  }, [unreadEndReached]);

  let currentDay;
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  const getTimeForDivider = (msgTime) => {
    let timeForDivider = '';
    if (!currentDay) {
      currentDay = msgTime;
      return timeForDivider;
    }

    if (msgTime.getFullYear() !== currentDay.getFullYear()
      || msgTime.getDay() !== currentDay.getDay()
      || msgTime.getMonth() !== currentDay.getMonth()) {
      timeForDivider = `${currentDay.getDate()} ${monthNames[currentDay.getMonth()]} ${currentDay.getFullYear()}`;
      currentDay = msgTime;
    }
    return timeForDivider;
  };

  const messagesJsx = messages.map((msg) => {
    const isMyMsg = (username === msg.sender || msg.sender === 'bot');
    const cs = document.getElementById('chat_scroller');
    const { id } = msg;
    const divider = getTimeForDivider(new Date(msg.time));
    return (
      <VisibilitySensor key={id} containment={cs} partialVisibility>
        {({ isVisible }) => {
          if (isVisible && id > maxId) maxId = id;
          return (
            <Message
              message={msg}
              isMyMsg={isMyMsg}
              timeForDivider={divider}
              creator={creator}
            />
          );
        }}
      </VisibilitySensor>

    );
  });

  if (messages.length !== 0) {
    const timeFirstMsg = new Date(messages[messages.length - 1].time);
    messagesJsx.push((
      <li key={timeFirstMsg.toString()}>
        <span className="message__new-day">
          {`${timeFirstMsg.getDate()} ${monthNames[timeFirstMsg.getMonth()]} ${timeFirstMsg.getFullYear()}`}
        </span>
      </li>
    ));
  }

  const handleScrollStop = () => {
    console.log(`Prev: ${chatScrollPosition.top}`);
    console.log(`Curr: ${scrollRef.current.getValues().top}`);
    if (oldestMsgId && oldestMsgId > 0) {
      dispatch({
        type: actions.NEXT_UNREAD_MESSAGE,
        payload: {
          roomId: activeRoomID,
          msgId: maxId,
        },
      });
    }
    dispatch({
      type: actions.UPDATE_ROOM_SCROLL,
      payload: {
        chatScrollPosition: scrollRef.current.getValues(),
        roomId: activeRoomID,
      },
    });
  };

  const handleOnScroll = () => {
    if (typeof lastScrollPos === 'undefined') {
      lastScrollPos = scrollRef.current.getValues().top;
      return;
    }

    if (lastScrollPos > scrollRef.current.getValues().top) {
      lastScrollPos = scrollRef.current.getValues().top;
      if ((lastScrollPos < 0.1) && !historyAlreadyFetched && !historyEndReached) {
        historyAlreadyFetched = true;
        let fromMsg = 0;
        if (messages.length > 0) {
          fromMsg = messages[messages.length - 1].id;
        } else {
          fromMsg = oldestMsgId > 0 ? oldestMsgId : latestMsg.id;
        }
        if (fromMsg > 0) {
          dispatch({
            type: actions.GET_MESSAGES,
            payload: {
              roomId: activeRoomID,
              msgId: fromMsg,
              count: config.messageHistoryQueryCount,
            },
          });
        }
      }
    }

    if (lastScrollPos < scrollRef.current.getValues().top) {
      lastScrollPos = scrollRef.current.getValues().top;
      if (lastScrollPos > 0.9 && oldestMsgId > 0) {
        let fromMsg = 0;
        if (messages.length > 0) {
          fromMsg = messages[0].id;
        } else {
          fromMsg = oldestMsgId > 0 ? oldestMsgId : latestMsg.id;
        }
        if (fromMsg > 0 && !unreadEndReached && !unreadAlreadyFetched) {
          unreadAlreadyFetched = true;
          dispatch({
            type: actions.GET_ROOM_UNREAD_MESSAGES,
            payload: {
              roomId: activeRoomID,
              msgId: fromMsg,
              count: config.messageHistoryQueryCount,
            },
          });
        }
      }
    }
  };

  return (
    <div className="room__chat">
      <div className="chat-messages">
        <Scrollbars
          id="chat_scroller"
          autoHide
          ref={scrollRef}
          onScroll={handleOnScroll}
          onScrollStop={handleScrollStop}
        >
          <ul className="chat_virtual">{messagesJsx}</ul>
        </Scrollbars>
      </div>
    </div>
  );
};

ChatMessages.propTypes = {
  activeRoomID: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  activeRoom: RoomType.isRequired,
};

export default ChatMessages;
