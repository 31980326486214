import { combineReducers } from 'redux';
import roomsReducer from './rooms';
import messagesReducer from './messages';
import userInfoReducer from './userInfo';
import chatMessagesUpdater from './chatMessagesUpdater';
import modals from './modals';

export default combineReducers({
  roomsReducer,
  messagesReducer,
  userInfoReducer,
  chatMessagesUpdater,
  modals,
});
