import { call, put } from 'redux-saga/effects';
import actions from '../actions';
import * as chatAPI from '../api/ChatAPI';

export default function* addUsersToRoom(a) {
  const { users, roomId } = a.payload;
  if (!Array.isArray(users) || users.length === 0) {
    console.error(new Error('invalid users param'));
    return;
  }
  if (typeof roomId !== 'string' || roomId.length === 0) {
    console.error(new Error('invalid roomId param'));
    return;
  }
  try {
    const { members } = yield call(chatAPI.addUsersToRoom, users, roomId);
    if (!members || !Array.isArray(members)) {
      console.error(new Error('chatAPI.addUsersToRoom: "members" isn`t valid'));
      return;
    }
    yield put({
      type: actions.UPDATE_ROOM_SETTINGS_STORE,
      payload: {
        id: roomId,
        room: {
          members,
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
}
