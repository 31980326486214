import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdDone, MdArrowBack } from 'react-icons/md';
import PropTypes from 'prop-types';
import Search from '../Common/Search';
import EditList from '../Common/EditList';
import actions from '../../actions';
import './createRoom.css';
import Switch from '../Common/Switch';


const CreateRoom = ({ closeCb }) => {
  const username = useSelector((state) => state.userInfoReducer.username);
  const dispatch = useDispatch();
  const roomSettingInputClass = 'cr-input';
  const roomSettingInputErrorClass = 'cr-input cr-input-error';
  const [inputRoomName, setInputRoomName] = useState('');
  const [inputClassName, setInputClassName] = useState(roomSettingInputClass);
  const [inputReadOnly, setInputReadOnly] = useState(false);
  const [addedUsers, setAddedUsers] = useState([]);
  const [inputModeration, setInputModeration] = useState(false);


  const addUserToListCb = (name) => {
    if (name === '' || addedUsers.indexOf(name) >= 0) return;
    setAddedUsers((prevState) => [...prevState, name]);
  };
  const removeUserCb = (name) => {
    setAddedUsers(addedUsers.filter((item) => item !== name));
  };

  const toggleInputError = (inputVal) => {
    if (inputVal === '') {
      setInputClassName(roomSettingInputErrorClass);
    } else if (inputClassName === roomSettingInputErrorClass) {
      setInputClassName(roomSettingInputClass);
    }
  };

  const applyChanges = () => {
    const name = inputRoomName.trim();
    if (name.length > 0) {
      dispatch({
        type: actions.CREATE_ROOM,
        payload: {
          name: inputRoomName,
          members: [...addedUsers, username],
          readOnly: inputReadOnly,
          moderable: inputModeration,
        },
      });
      closeCb();
    }
    toggleInputError(name);
    setInputRoomName(name);
  };

  const onChangeReadOnly = (e) => {
    setInputReadOnly(e.target.checked);
  };
  const onChangeModeration = (e) => {
    setInputModeration(e.target.checked);
  };

  const onChangeRoomName = (e) => {
    const { value } = e.target;
    toggleInputError(value);
    if (value.length <= 255) {
      setInputRoomName(value);
    }
  };

  return (
    <div className="create-room">
      <div className="create-room__header">
        <div onClick={closeCb} className="room-header__btn">
          <MdArrowBack title="Back" />
        </div>
        <div className="create-room__header-text" title="Create room">Create room</div>
        <div
          onClick={applyChanges}
          className="room-header__btn"
        >
          <MdDone title="Apply" />
        </div>
      </div>
      <div className="create-room__body">
        <div className="cr__body__room-name">
          <input
            className={inputClassName}
            type="text"
            onChange={onChangeRoomName}
            value={inputRoomName}
            placeholder="Write a room name…"
          />
        </div>
        <div className="cr__body__room-add-participants">
          <div className="add-room__search">
            <Search addUserToListCb={addUserToListCb} />
          </div>
          <EditList
            className="body__participants-list"
            listAddedUsers={addedUsers}
            removeUserCb={removeUserCb}
            showClose
          />
        </div>
        <div className="cr__toggle-wrapper">
          <div className="cr__label">Make the room readonly</div>
          <div className="cr__toggle">
            <Switch startValue={inputReadOnly} switchCb={onChangeReadOnly} />
          </div>
        </div>
        <div className="cr__toggle-wrapper">
          <div className="cr__label">Moderation</div>
          <div className="cr__toggle">
            <Switch startValue={inputModeration} switchCb={onChangeModeration} />
          </div>
        </div>
      </div>
      <div className="cr__apply-btn-wrapper">
        <div
          className="cr-body__btn"
          onClick={applyChanges}
        >
          Apply
        </div>
      </div>
    </div>
  );
};

CreateRoom.propTypes = {
  closeCb: PropTypes.func.isRequired,
};

export default CreateRoom;
