import { select, put } from 'redux-saga/effects';
import actions from '../actions';
import { getActiveRoomId } from '../selectors';


export default function* roomDeleted(a) {
  const { roomId } = a.payload;
  if (typeof roomId !== 'string' || roomId.length === 0) {
    console.log(new Error('invalid roomId param'));
    return;
  }
  try {
    const activeRoomId = yield select(getActiveRoomId);
    if (activeRoomId === roomId) {
      yield put({
        type: actions.SET_ACTIVE_ROOM,
        payload: '',
      });
    }
    yield put({
      type: actions.DELETE_ROOM_STORE,
      payload: {
        roomId,
      },
    });
  } catch (e) {
    console.log(e);
  }
}
