import { call, put, select } from 'redux-saga/effects';
import actions from '../actions';
import * as chatAPI from '../api/ChatAPI';
import { getMessages, getRooms, getUsername } from '../selectors';

export default function* nextUnreadMessage(action) {
  // eslint-disable-next-line prefer-const
  let { roomId, msgId } = action.payload;
  if (roomId === '') {
    console.error(new Error('nextUnreadMessage saga: action roomId value is empty'));
    return;
  }
  if (msgId < 0) {
    console.error(new Error('nextUnreadMessage saga: action msgId value lt 0'));
    return;
  }

  try {
    const roomsState = yield select(getRooms);
    const messagesState = yield select(getMessages);
    const { messages = [] } = messagesState[roomId] || {};
    const room = roomsState[roomId] || {};
    const { oldestMsgId = 0, latestMsg = { id: 0 } } = room;
    const allMsgRead = oldestMsgId === 0;
    const viewportBeforeUnreadMsg = msgId > 0 && oldestMsgId > 0 && oldestMsgId > msgId;
    if (allMsgRead || viewportBeforeUnreadMsg) return;

    if (latestMsg.id === msgId) msgId = 0;

    yield call(chatAPI.nextUnreadMessage, roomId, msgId);
    const username = yield select(getUsername);

    let unreadMsgCount = 0;
    if (msgId > 0) {
      const allNewMessages = messages.filter((msg) => msg.id > msgId && msg.id <= latestMsg.id);
      const newMessages = allNewMessages.filter((msg) => msg.sender !== username);
      unreadMsgCount = newMessages.length;
    }

    const updateRoomAction = {
      type: actions.UPDATE_ROOM_SETTINGS_STORE,
      payload: {
        id: roomId,
        room: {
          oldestMsgId: msgId,
          unreadMsgCount,
        },
      },
    };

    yield put(updateRoomAction);
  } catch (e) {
    console.error(e);
  }
}
