import { call, put, select } from 'redux-saga/effects';
import actions from '../actions';
import * as chatAPI from '../api/ChatAPI';
import config from '../config';
import { getRooms } from '../selectors';

export const splitMessage = (message, maxLen, separator = ' ') => {
  const messages = [];
  if (message.length <= maxLen) {
    messages.push(message);
    return messages;
  }
  let startPos = 0;
  let endPos = maxLen;
  while (startPos <= message.length) {
    const nextMsg = message.substring(startPos, endPos);
    if (nextMsg.length === 0) break;
    messages.push(nextMsg);
    startPos = endPos;
    endPos += maxLen;
  }
  messages.forEach((val, idx, arr) => {
    if (val[val.length - 1] === separator) {
      return;
    }
    // having next part
    if (idx + 1 < arr.length) {
      const lastSpaceIdx = val.lastIndexOf(separator);
      if (lastSpaceIdx === -1) return;
      const transPart = val.substring(lastSpaceIdx + 1);
      messages[idx + 1] = transPart + arr[idx + 1];
      messages[idx] = val.substring(0, lastSpaceIdx);
    }
  });
  return messages;
};

export default function* sendMessage(a) {
  const { roomId, msgText } = a.payload;
  if (typeof roomId !== 'string' || roomId.length === 0) {
    console.log('invalid roomId param');
    return;
  }
  if (typeof msgText !== 'string' || !msgText.match(/[\S]/)) {
    console.log('invalid text param');
    return;
  }
  try {
    const messages = splitMessage(msgText, config.sendingSymbolsCount);

    for (let i = 0; i < messages.length; i++) {
      const {
        sender,
        roomId: respRoomId,
        text,
        id,
        time,
        isModerated,
      } = yield call(chatAPI.sendMessage, { roomId, text: messages[i] });
      if (respRoomId !== roomId) {
        console.log('invalid roomId field in response');
        return;
      }
      yield put({
        type: actions.SEND_MESSAGE_STORE,
        payload: {
          id,
          sender,
          roomId,
          text,
          time,
          isModerated,
        },
      });
      yield put({
        type: actions.CHAT_UPDATED,
        payload: {
          client: sender,
          roomId,
        },
      });
      const rooms = yield select(getRooms);
      yield put({
        type: actions.UPDATE_ROOM,
        payload: {
          ...rooms[roomId],
          id: roomId,
          managerId: sender,
          botActivity: false,
          latestMsg: {
            id,
            sender,
            text,
            time,
            isModerated,
          },
        },
      });
    }
  } catch (e) {
    console.log(e.message);
  }
}
