import { all, takeEvery } from 'redux-saga/effects';
import actions from '../actions';
import addUsersToRoom from './addUsersToRoom';
import getUserRooms from './getUserRooms';
import deleteUsersFromRoom from './deleteUsersFromRoom';
import createRoom from './createRoom';
import getHistoryMessages from './getHistoryMessages';
import sendMessage from './sendMessage';
import updateRoomSettings from './updateRoomSettings';
import clearHistory from './clearHistory';
import getRoomUnreadMessages from './getRoomUnreadMessages';
import messageReceived from './receivedMessage';
import nextUnreadMessage from './nextUnreadMessage';
import nextUnreadId from './nextUnreadId';
import invitedToRoom from './invitedToRoom';
import usersDeletedFromRoom from './usersDeletedFromRoom';
import modMessageRejected from './modMessageRejected';
import rejectModMessage from './rejectModMessage';
import approveModMessage from './approveModMessage';
import approvedMessage from './approvedMessage';
import startPrivate from './startPrivate';
import deleteRoom from './deleteRoom';
import roomDeleted from './roomDeleted';
import quitRoom from './quitRoom';
import changeBotActivity from './changeBotActivity';


export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_USERS_TO_ROOM, addUsersToRoom),
    takeEvery(actions.DELETE_USERS_FROM_ROOM, deleteUsersFromRoom),
    takeEvery(actions.GET_USER_ROOMS, getUserRooms),
    takeEvery(actions.CREATE_ROOM, createRoom),
    takeEvery(actions.GET_MESSAGES, getHistoryMessages),
    takeEvery(actions.SEND_MESSAGE, sendMessage),
    takeEvery(actions.UPDATE_ROOM_SETTINGS, updateRoomSettings),
    takeEvery(actions.CLEAR_HISTORY, clearHistory),
    takeEvery(actions.GET_ROOM_UNREAD_MESSAGES, getRoomUnreadMessages),
    takeEvery(actions.MESSAGE_RECEIVED, messageReceived),
    takeEvery(actions.NEXT_UNREAD_MESSAGE, nextUnreadMessage),
    takeEvery(actions.NEXT_UNREAD_ID, nextUnreadId),
    takeEvery(actions.INVITED_TO_ROOM, invitedToRoom),
    takeEvery(actions.USERS_DELETED, usersDeletedFromRoom),
    takeEvery(actions.MOD_MESSAGE_REJECTED, modMessageRejected),
    takeEvery(actions.APPROVED_MESSAGE, approvedMessage),
    takeEvery(actions.APPROVE_MOD_MESSAGE, approveModMessage),
    takeEvery(actions.REJECT_MOD_MESSAGE, rejectModMessage),
    takeEvery(actions.START_PRIVATE, startPrivate),
    takeEvery(actions.DELETE_ROOM, deleteRoom),
    takeEvery(actions.ROOM_DELETED, roomDeleted),
    takeEvery(actions.QUIT_ROOM, quitRoom),
    takeEvery(actions.CHANGE_BOT_ACTIVITY, changeBotActivity),
  ]);
}
