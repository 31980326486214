import { call, put, select } from 'redux-saga/effects';
import * as chatAPI from '../api/ChatAPI';
import actions from '../actions';
import { getMessages, getRooms } from '../selectors';

export default function* getRoomUnreadMessages(a) {
  const { roomId, msgId, count } = a.payload;
  if (typeof roomId !== 'string' || roomId.length === 0) {
    console.error(new Error('getRoomUnreadMessages saga: invalid action argument "roomId"'));
    return;
  }
  if (typeof msgId !== 'number' || msgId <= 0) {
    console.error(new Error('getRoomUnreadMessages saga: invalid action argument "msgId"'));
    return;
  }
  if (typeof count !== 'number' || count <= 0) {
    console.error(new Error('getRoomUnreadMessages saga: invalid action argument "count"'));
    return;
  }
  try {
    const messagesStore = yield select(getMessages);
    const rooms = yield select(getRooms);

    let isRequest = true;
    const roomMsgs = messagesStore[roomId];
    const room = rooms[roomId];
    const { messages: msgs = [] } = roomMsgs || {};
    const { id: lastMessageId = 0 } = room?.latestMsg || {};
    if (msgs.length === 0 && lastMessageId === 0) {
      isRequest = false;
    }
    if (msgs.length > 0 && msgs[0].id === lastMessageId) {
      isRequest = false;
    }
    if (!isRequest) {
      return;
    }
    const { moderable } = room;
    const getMessagesFunc = moderable
      ? chatAPI.getRoomUnreadModMessages : chatAPI.getRoomUnreadMessages;

    const responseArgs = yield call(getMessagesFunc, roomId, msgId, count);
    const { roomId: roomIdResponse, messages } = responseArgs;
    if (typeof roomIdResponse !== 'string' || roomIdResponse.length === 0 || roomIdResponse !== roomId) {
      console.error(new Error('getRoomUnreadMessages saga: invalid response argument "roomId"'));
      return;
    }
    if (Array.isArray(messages) && messages.length > 0) {
      yield put({
        type: actions.GET_ROOM_UNREAD_MESSAGES_STORE,
        payload: {
          messages,
          roomId,
        },
      });
    }
    if (!Array.isArray(messages) || messages.length < count) {
      yield put({
        type: actions.UNREAD_END_REACHED_STORE,
        payload: {
          roomId,
        },
      });
    }
  } catch (e) {
    console.error(e);
  }
}
