import { call, put } from 'redux-saga/effects';
import actions from '../actions';
import * as chatAPI from '../api/ChatAPI';

export default function* getUserRooms() {
  try {
    const args = yield call(chatAPI.getUserRooms);
    if (!Array.isArray(args.rooms)) {
      console.error('invalid rooms format');
      return;
    }
    const rooms = {};
    if (args.rooms.length > 0) {
      args.rooms.forEach((room) => {
        const {
          id,
          botActivity,
          managerId,
          members,
          unreadMsgCount,
          oldestMsgId,
          latestMsg,
        } = room;
        rooms[id] = {
          name: id,
          botActivity,
          managerId,
          members,
          unreadMsgCount,
          oldestMsgId,
          latestMsg,
        };
      });
      yield put({
        type: actions.GET_USER_ROOMS_STORE,
        payload: {
          rooms,
        },
      });
    }
  } catch (e) {
    console.error(e);
  }
}
