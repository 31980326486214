import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdArrowBack } from 'react-icons/md';
import PropTypes from 'prop-types';
import actions from '../../actions';
import './Settings.css';
import Switch from '../Common/Switch';

const RoomSettings = (props) => {
  const {
    closeCb,
    roomName,
  } = props;
  const roomSettingInputClass = 'rsi-input';
  const roomSettingInputErrorClass = 'rsi-input rsi-error';
  const roomSettingApplyBtnActive = 'rs-body__btn__change-name';
  const roomSettingApplyBtnDisable = 'rs-body__btn__change-name btn-disable';
  const { activeRoomID, rooms } = useSelector((state) => state.roomsReducer);
  const [inputRoomName, setInputRoomName] = useState(roomName);
  const [inputClassName, setInputClassName] = useState(roomSettingInputClass);
  const [inputModeration, setInputModeration] = useState(rooms[activeRoomID].moderable);
  const [inputReadOnly, setInputReadOnly] = useState(rooms[activeRoomID].readOnly);
  const [appleBtnClassName, setApplyBtnClassName] = useState(roomSettingApplyBtnDisable);
  const dispatch = useDispatch();
  const firstRoomName = roomName;
  const clearHistoryAction = () => dispatch({
    type: actions.CLEAR_HISTORY,
    payload: {
      roomId: activeRoomID,
    },
  });

  const deleteRoomAction = () => dispatch({
    type: actions.DELETE_ROOM,
    payload: {
      roomId: activeRoomID,
    },
  });

  const showConfirmModalClearHistory = () => {
    dispatch({
      type: actions.OPEN_CONFIRM_MODAL,
      payload: {
        msg: 'Are you sure you want to clear conversation history? Chat history will be deleted.',
        resultCb: (result) => {
          if (result) clearHistoryAction();
        },
      },
    });
  };

  const showConfirmModalDeleteRoom = () => {
    dispatch({
      type: actions.OPEN_CONFIRM_MODAL,
      payload: {
        msg: 'Are you sure you want to delete room?',
        resultCb: (result) => {
          if (result) deleteRoomAction();
        },
      },
    });
  };

  const toggleClassNames = (inputVal, wasChanges) => {
    if (inputVal === '') {
      setInputClassName(roomSettingInputErrorClass);
      setApplyBtnClassName(roomSettingApplyBtnDisable);
    } else if (inputClassName === roomSettingInputErrorClass) {
      setInputClassName(roomSettingInputClass);
    }

    if (inputVal !== firstRoomName && !wasChanges && inputVal !== '') {
      setApplyBtnClassName(roomSettingApplyBtnActive);
    } else if (inputVal === firstRoomName || wasChanges) {
      setApplyBtnClassName(roomSettingApplyBtnDisable);
    }
  };

  const applyChanges = (changes) => {
    dispatch({
      type: actions.UPDATE_ROOM_SETTINGS,
      payload: {
        id: activeRoomID,
        name: inputRoomName,
        readOnly: inputReadOnly,
        moderable: inputModeration,
        ...changes,
      },
    });
  };

  const applyNameChanges = () => {
    const name = inputRoomName.trim();
    if (name.length > 0) {
      setInputRoomName(name);
      applyChanges({ name });
    }
    toggleClassNames(name, true);
  };

  const onChangeInput = (e) => {
    const { value } = e.target;
    toggleClassNames(value);

    if (value.length <= 255) {
      setInputRoomName(value, false);
    }
  };

  const onChangeReadOnly = (e) => {
    setInputReadOnly(e.target.checked);
    applyChanges({ readOnly: e.target.checked });
  };

  const onChangeModeration = (e) => {
    setInputModeration(e.target.checked);
    applyChanges({ moderable: e.target.checked });
  };

  return (
    <div className="room-settings">
      <div className="room-settings__header">
        <div onClick={closeCb} className="room-settings-header__btn">
          <MdArrowBack title="Back" />
        </div>
        <div className="room-settings_header-text" title="Create room">Room settings</div>
      </div>
      <div className="room-settings__body">
        <div className="room-settings__body__room-name">
          <div className="rsi-header">Room name</div>
          <div className="rsi__apply-btn-wrapper">
            <input
              className={inputClassName}
              type="text"
              onChange={onChangeInput}
              value={inputRoomName}
            />
            <div
              className={appleBtnClassName}
              onClick={applyNameChanges}
            >
            Apply
            </div>
          </div>
        </div>
        <div className="room-settings__body__read_only">
          <div className="rsi-header">Read only</div>
          <Switch startValue={inputReadOnly} switchCb={onChangeReadOnly} />
        </div>
        <div className="room-settings__body__read_only">
          <div className="rsi-header">Moderation</div>
          <Switch startValue={inputModeration} switchCb={onChangeModeration} />
        </div>
        <div className="room-settings__body__read_only">
          <div className="rsi-header">Clear conversation history</div>
          <div className="rs-body__btn rsi-header" onClick={showConfirmModalClearHistory}>Clear</div>
        </div>
        <div className="room-settings__body__read_only">
          <div className="rsi-header">Delete room</div>
          <div className="rs-body__btn rsi-header" onClick={showConfirmModalDeleteRoom}>Delete</div>
        </div>
      </div>

    </div>
  );
};

RoomSettings.propTypes = {
  closeCb: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
};

export default RoomSettings;
