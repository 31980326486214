import { call, select, put } from 'redux-saga/effects';
import * as chatAPI from '../api/ChatAPI';
import { getMessages } from '../selectors';
import actions from '../actions';

export default function* approveModMessage(a) {
  try {
    console.log('approveModMessage', a);
    const { id, roomId } = a.payload;
    if (typeof id !== 'number' || id < 1) {
      console.error('approveModMessage saga: invalid id parameter');
      return;
    }
    if (typeof roomId !== 'string' || roomId.length === 0) {
      console.error('approveModMessage saga: invalid roomId parameter');
      return;
    }
    const resp = yield call(chatAPI.approveModMessage, id, roomId);
    const { oldId, message } = resp;

    const messagesState = yield select(getMessages);
    const roomMessages = messagesState[roomId]?.messages;
    if (!roomMessages) {
      console.error('roomMessages isn`t existed');
      return;
    }

    const { sender } = message;

    messagesState[roomId].messages = [message, ...roomMessages]
      .filter((msg) => msg.id !== oldId);

    yield put({
      type: actions.SET_MESSAGES_STORE,
      payload: messagesState,
    });

    yield put({
      type: actions.CHAT_UPDATED,
      payload: {
        client: sender,
        roomId,
      },
    });

    yield put({
      type: actions.UPDATE_ROOM_SETTINGS_STORE,
      payload: {
        id: roomId,
        room: {
          latestMsg: message,
        },
      },
    });
  } catch (e) {
    console.error(e);
  }
}
