import React from 'react';
import { useDispatch } from 'react-redux';
import { MdArrowBack } from 'react-icons/md';
import PropTypes from 'prop-types';
import Search from '../Common/Search';
import './StartPrivate.css';
import actions from '../../actions';


const StartPrivate = ({ closeCb }) => {
  const dispatch = useDispatch();

  const handleUserPick = (userId) => {
    dispatch({
      type: actions.START_PRIVATE,
      payload: {
        partner: userId,
      },
    });
    closeCb();
  };


  return (
    <div className="create-room">
      <div className="create-room__header">
        <div onClick={closeCb} className="room-header__btn">
          <MdArrowBack title="Back" />
        </div>
        <div className="create-room__header-text" title="Create room">Start private chat with</div>
      </div>
      <div className="create-room__body">
        <div className="cr__body__room-add-participants">
          <div className="add-room__search">
            <Search addUserToListCb={handleUserPick} placeholder="Select partner..." />
          </div>
        </div>
      </div>
    </div>
  );
};

StartPrivate.propTypes = {
  closeCb: PropTypes.func.isRequired,
};

export default StartPrivate;
