import React, { useRef } from 'react';
import { MdClear } from 'react-icons/md';
import './editList.css';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import config from '../../../config';
import { getAvatar } from '../../../api/WaivioAPI/index';

const EditList = (props) => {
  const { listAddedUsers, removeUserCb, showClose } = props;
  const scrollRef = useRef(undefined);
  const list = listAddedUsers.map((user) => (
    <li
      key={user.toString()}
      className="participants-list__item"
    >
      <div className="item__image-wrapper">
        <img className="item__img" src={getAvatar(user)} alt="" />
      </div>
      <div className="item__text">{user}</div>
      <div className="item__icon" onClick={() => removeUserCb(user)}>
        {showClose && <MdClear />}
      </div>
    </li>
  ));

  return (
    <Scrollbars
      ref={scrollRef}
      autoHide
      className="participants-wrapper"
      autoHideTimeout={config.scrollbarHideTimeout}
      style={{ flex: 1 }}
      autoHideDuration={config.scrollbarHideDuration}
    >
      <ul className="participants-list">
        {list}
      </ul>
    </Scrollbars>
  );
};

EditList.propTypes = {
  listAddedUsers: PropTypes.instanceOf(Array).isRequired,
  removeUserCb: PropTypes.func.isRequired,
  showClose: PropTypes.bool.isRequired,
};

export default EditList;
