import config from '../../config';

const { originWhitelist } = config;
let currentOrigin = '';

const sendMessage = (msg, origin) => {
  window.parent.postMessage(msg, origin);
};

let init = () => {};

let authConnection = () => {};

let startChat = () => {};

export const sendInitResponse = (sessionID) => {
  sendMessage({
    cmd: 'init_response',
    args: {
      session_id: sessionID,
    },
  }, currentOrigin);
};

export const sendAuthConnectionResponse = (status) => {
  sendMessage({
    cmd: 'auth_connection_response',
    args: {
      status,
    },
  }, currentOrigin);
};

export const sendStartChatResponse = (status) => {
  sendMessage({
    cmd: 'start_chat_response',
    args: {
      status,
    },
  }, currentOrigin);
};

export const sendNewEvent = (eventName) => {
  sendMessage({
    cmd: 'new_event',
    args: {
      name: eventName,
    },
  }, currentOrigin);
};

export const sendAvaClickedEvent = (username) => {
  sendMessage({
    cmd: 'avatar_clicked',
    args: {
      username,
    },
  }, currentOrigin);
};

export const sendConnected = () => {
  originWhitelist.forEach((origin) => {
    sendMessage({
      cmd: 'connected',
    }, origin);
  });
};

export const sendCloseChat = () => {
  sendMessage({
    cmd: 'close_chat',
  }, currentOrigin);
};

export const onInit = (initCb) => {
  init = initCb;
};

export const onAuthConnection = (authConnectionCb) => {
  authConnection = authConnectionCb;
};

export const onStartChat = (startChatCb) => {
  startChat = startChatCb;
};

const routeMsg = (msg) => {
  const { origin } = msg;
  if (!originWhitelist.includes(origin)) {
    console.error('ParentAPI: message has been received from invalid origin');
    return;
  }
  currentOrigin = origin;
  const { cmd, args } = msg.data;

  if (typeof cmd === 'undefined') {
    console.log('ParentAPI: post message data has no "cmd" property');
    return;
  }

  switch (cmd) {
    case 'init':
      init(args.username);
      break;
    case 'auth_connection':
      authConnection(args);
      break;
    case 'start_chat':
      startChat(args.partner);
      break;
    default:
      console.error(`ParentAPI: invalid cmd: ${cmd}`);
  }
};

window.addEventListener('message', routeMsg);
