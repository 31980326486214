import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  MdArrowBack,
  // MdClose,
  MdPersonAdd,
  MdSettings,
} from 'react-icons/md';
import PropTypes from 'prop-types';
import AddUsersToRoom from '../../AddUsersToRoom';
// import { sendCloseChat } from '../../../api/ParentAPI/index';
import RoomSettings from '../../Settings';
import actions from '../../../actions';
import './RoomHeader.css';
import Switch from '../../Common/Switch';
// import ChatInfo from './ChatInfo';

const RoomHeader = (props) => {
  const {
    roomId,
    roomName,
    setRoomListVisibility,
    isCreator,
    // membersCount,
    roomType,
    botActivity,
  } = props;
  const dispatch = useDispatch();
  const [isVisibleAddUser, setAddUserVisibility] = useState(false);
  const toggleAddUserToRoom = () => setAddUserVisibility((prevState) => !prevState);
  const [isVisibleSettings, setSettingsVisibility] = useState(false);
  const toggleRoomSettings = () => setSettingsVisibility((prevState) => !prevState);
  const addPersonTitle = roomType === 'private' ? 'Add a group room' : `Edit members of ${roomName}`;

  const dispatchSetActiveRoom = () => {
    dispatch({
      type: actions.SET_ACTIVE_ROOM,
      payload: '',
    });
  };

  const changeBotActivity = () => {
    dispatch({
      type: actions.CHANGE_BOT_ACTIVITY,
      payload: {
        roomId,
      },
    });
  };

  // const handleCloseBtn = () => {
  //   sendCloseChat();
  //   dispatchSetActiveRoom();
  //   setRoomListVisibility(true);
  // };
  const handleBackBtn = () => {
    dispatchSetActiveRoom();
    setRoomListVisibility(true);
  };
  // const handleExitChatBtn = () => {
  //   if (isCreator && roomType !== 'private') {
  //     dispatch({
  //       type: actions.OPEN_CONFIRM_MODAL,
  //       payload: {
  //         msg: 'Because of You a creator the room will be deleted. Are you sure you want to quit from this room?',
  //         resultCb: (result) => {
  //           if (result) {
  //             dispatch({
  //               type: actions.DELETE_ROOM,
  //               payload: { roomId },
  //             });
  //           }
  //         },
  //       },
  //     });
  //   } else {
  //     dispatch({
  //       type: actions.OPEN_CONFIRM_MODAL,
  //       payload: {
  //         msg: 'Are you sure you want to quit from this room?',
  //         resultCb: (result) => {
  //           if (result) {
  //             dispatch({
  //               type: actions.QUIT_ROOM,
  //               payload: { roomId },
  //             });
  //           }
  //         },
  //       },
  //     });
  //   }
  // };
  return (
    <div className="room__header">
      {isVisibleAddUser && (
        <AddUsersToRoom
          closeCb={toggleAddUserToRoom}
          roomName={roomName}
        />
      )}
      {isVisibleSettings && (
        <RoomSettings
          closeCb={toggleRoomSettings}
          roomName={roomName}
        />
      )}

      <div className="room__back-btn" onClick={handleBackBtn}><MdArrowBack /></div>
      <div className="room__info" title={roomName}>
        <div className="room__name">{roomName}</div>
        <span className={`room__bot-activity ${botActivity ? 'working' : 'paused'}`}>
          {botActivity ? 'working' : 'paused'}
        </span>
        {/* <ChatInfo roomType={roomType} membersCount={membersCount} /> */}
      </div>
      {(isCreator || roomType === 'private')
        && (
          <div
            className="room__add-users-btn"
            onClick={toggleAddUserToRoom}
          >
            <MdPersonAdd title={addPersonTitle} />
          </div>
        )}
      {isCreator && roomType === 'group'
        && (
          <div
            className="room__settings-btn"
            onClick={toggleRoomSettings}
          >
            <MdSettings title="settings" />
          </div>
        )}
      {/* <div className="room__exit-chat-btn" onClick={handleExitChatBtn}><MdExitToApp /></div> */}
      <div className="room__bot-activity-change" title="change bot activity">
        <Switch switchCb={changeBotActivity} startValue={botActivity} />
      </div>
      {/* <div className="room__close-chat-btn" onClick={handleCloseBtn}><MdClose /></div> */}
    </div>
  );
};

RoomHeader.propTypes = {
  roomId: PropTypes.string.isRequired,
  roomName: PropTypes.string.isRequired,
  setRoomListVisibility: PropTypes.func.isRequired,
  isCreator: PropTypes.bool.isRequired,
  // membersCount: PropTypes.number.isRequired,
  roomType: PropTypes.string.isRequired,
};

export default RoomHeader;
