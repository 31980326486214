import React from 'react';
// import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';
// import { sendCloseChat } from '../../../api/ParentAPI';

const RoomPlaceholder = ({ text }) => (
  <div className="room">
    {/* <div className="close_bth" onClick={sendCloseChat}> */}
    {/*  <MdClose /> */}
    {/* </div> */}
    <div className="room__incative">
      <div className="room__incative-content">
        {text}
      </div>
    </div>
  </div>
);

RoomPlaceholder.propTypes = {
  text: PropTypes.string.isRequired,
};

export default RoomPlaceholder;
