import React, { useRef, useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import * as WaivioAPI from '../../../api/WaivioAPI';
import DebounceDelayer from '../../../helper/DebounceDelayer';
import './Search.css';
import config from '../../../config';

const resizeSearchList = () => {
  const elem = document.getElementById('search-wrapper');
  elem.style.height = `${(window.innerHeight - elem.offsetTop).toString()}px`;
};

const Search = (props) => {
  const {
    addUserToListCb,
    placeholder,
  } = props;
  const [foundUsers, setFoundUsers] = useState([]);
  const searchInputRef = useRef(undefined);
  const scrollRef = useRef(undefined);
  const [outputClassName, setOutputClassName] = useState('search-users__output-hide');

  useEffect(() => {
    resizeSearchList();
  }, []);
  useEffect(() => {
    window.addEventListener('resize', resizeSearchList);
    return () => {
      window.removeEventListener('resize', resizeSearchList);
    };
  });

  const handleUsernameInput = DebounceDelayer(() => {
    const nameExcerpt = searchInputRef.current.value.replace(/\s/g, '');
    WaivioAPI.searchUser(nameExcerpt, config.userSearchMaxResultCount)
      .then((list) => {
        setFoundUsers(list);
        setOutputClassName('search-users__output');
      }).catch(() => {
        setFoundUsers([]);
      });
  }, config.userSearchInputDelay);
  const handleItemClick = (name) => {
    searchInputRef.current.value = '';
    addUserToListCb(name);
    setFoundUsers([]);
    setOutputClassName('search-users__output-hide');
  };

  const delayerHidePanel = DebounceDelayer(() => {
    setOutputClassName('search-users__output-hide');
  }, 300);

  const hidePanelOfUserList = (e) => {
    if (e.target.value === '') {
      delayerHidePanel();
    }
  };

  const handleInputOnChange = (e) => {
    handleUsernameInput();
    hidePanelOfUserList(e);
  };

  const listItems = foundUsers.map((user) => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      key={user.account}
      className="participants-list__item"
      onClick={() => handleItemClick(user.account)}
    >
      <div className="item__image-wrapper">
        <img className="item__img" src={WaivioAPI.getAvatar(user.account)} alt="" />
      </div>
      <div className="item__text">{user.account}</div>
    </li>
  ));

  const drawList = foundUsers && (
    <Scrollbars
      ref={scrollRef}
      autoHide
      className={outputClassName}
      autoHideTimeout={config.scrollbarHideTimeout}
      autoHideDuration={config.scrollbarHideDuration}
    >
      <ul>{listItems}</ul>
    </Scrollbars>
  );

  return (
    <div
      className="search-wrapper"
      id="search-wrapper"
    >
      <div className="search-users">
        <input
          type="text"
          className="search-users__input"
          ref={searchInputRef}
          onChange={handleInputOnChange}
          placeholder={placeholder}
        />
      </div>
      {drawList}
    </div>
  );
};

Search.defaultProps = {
  placeholder: 'Add new member',
};

Search.propTypes = {
  addUserToListCb: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default Search;
